import * as React from 'react';
import { useContext } from 'react';

import { AudiCopyS } from '@volkswagen-onehub/audi-core-ci-components';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { useConfiguratorData } from '@oneaudi/configurator-data-context';

import { FootnoteReference } from '../../../utils/footnote-references/footnote-reference';
import {
  AudiBasketConfigurationValueM,
  AudiBasketConfigurationValueS,
} from '../../audi-basket-configuration-values/audi-basket-configuration-values';
import { AudiBasketContext } from '../../audi-basket/audi-basket';
import { messages } from '../../../utils/i18n/messages';
import AudiBasketFinanceLink from '../../audi-basket-finance-link/audi-basket-financing-link';
import AudiBasketRateCheckbox from '../../audi-basket-rate-checkbox/audi-basket-rate-checkbox';
import AudiBasketOverviewPricesExtraPrices from '../../audi-basket-extra-prices/audi-basket-extra-prices';
import { createMobileViewExtendedAction } from '../../../utils/actions/basket-actions';
import { DeliveryCostsButton } from '../../audi-basket-price-details/delivery-costs/delivery-costs-button';
import {
  MarketVariantProps,
  WltpStatus,
} from '../../audi-basket-price-details/audi-basket-price-details';
import AudiBasketIncentivesBlock from '../../audi-basket-incentives-block/audi-basket-incentives-block';

export type AudiBasketOverviewPricesVariantAProps = {
  hideFootnoteOnTaxDetailsLine?: boolean;
  showVatTaxExtraForBev?: boolean;
} & MarketVariantProps;

const AudiBasketOverviewPricesVariantA: React.ForwardRefRenderFunction<
  HTMLDivElement,
  AudiBasketOverviewPricesVariantAProps
> = ({
  hideFootnoteOnTaxDetailsLine,
  showVatTaxExtraForBev,
  showDeliveryCostsButton,
  layerUrl,
}) => {
  const { basketState, basketDispatch } = useContext(AudiBasketContext);
  const {
    config: { FinanceLink },
    marketContextService,
  } = basketState;

  const classPrefix = 'audi-basket-overview-prices__';
  const labelClass = `${classPrefix}price-label`;
  const valueClass = `${classPrefix}price-value`;

  const totalPriceField =
    useConfiguratorData<number>('prices.chargesRaw') > 0
      ? 'prices.totalWithCharges'
      : 'prices.total';

  const checkValueTotalPriceField =
    useConfiguratorData<number>('prices.chargesRaw') > 0
      ? 'prices.totalWithChargesRaw'
      : 'prices.totalRaw';

  const hasRate = useConfiguratorData<number>('prices.totalRateRaw') > 0;
  const showFinanceProductLink = hasRate && FinanceLink;

  const totalRateLabel = useI18n(messages.totalRate);
  const totalPriceLabel = useI18n(messages.priceTotal);
  const totalPriceLabelBEV = useI18n(messages.priceTotalBEV);

  const wltpStatusData = useConfiguratorData<WltpStatus>('wltp-status');
  const isBEV = wltpStatusData?.engineType === 'PEV';
  const showBEVLabel = isBEV && Boolean(marketContextService?.hasEnvScope('BASKET_BEV_LABELS'));

  const totalPriceDisclaimerID = showBEVLabel ? 'nemo-fn_price_BEV' : 'nemo-fn_price';

  return (
    <>
      <div className="audi-basket-overview-prices__base-prices audi-basket-overview-prices__desktop-hide">
        <AudiBasketConfigurationValueS
          field="prices.model"
          rowField="prices.modelRaw"
          additionalCss={valueClass}
        >
          <AudiCopyS additionalClasses={labelClass}>{useI18n(messages.basePrice)}</AudiCopyS>
        </AudiBasketConfigurationValueS>
        <AudiBasketConfigurationValueS
          field="prices.options"
          rowField="prices.optionsRaw"
          additionalCss={valueClass}
        >
          <AudiCopyS additionalClasses={labelClass}>{useI18n(messages.equipment)}</AudiCopyS>
        </AudiBasketConfigurationValueS>
        <AudiBasketOverviewPricesExtraPrices
          LabelComponent={AudiCopyS}
          ValueComponent={AudiBasketConfigurationValueS}
          ClassBase={classPrefix}
          hideFootnoteOnTaxDetailsLine={hideFootnoteOnTaxDetailsLine}
          showVatTaxExtraForBev={showVatTaxExtraForBev}
        />
      </div>
      <AudiBasketIncentivesBlock variant="mobile" tagName={AudiCopyS} />
      <AudiBasketConfigurationValueM
        additionalCss={`${valueClass} audi-basket__price-total`}
        additionalProps={{ bold: true }}
        field={totalPriceField}
        rowField={checkValueTotalPriceField}
      >
        <AudiCopyS additionalClasses={labelClass}>
          {showBEVLabel ? totalPriceLabelBEV : totalPriceLabel}
          <FootnoteReference
            refId={totalPriceDisclaimerID}
            inverted
            onClick={(): void => basketDispatch(createMobileViewExtendedAction(false))}
          />
        </AudiCopyS>
      </AudiBasketConfigurationValueM>
      {showDeliveryCostsButton && (
        <DeliveryCostsButton
          additionalCss="audi-basket-overview-prices__delivery-cost audi-basket-overview-prices__desktop-hide"
          layerUrl={layerUrl}
        />
      )}
      <div className="audi-basket__finance-data">
        {hasRate && (
          <AudiBasketConfigurationValueS
            field="prices.totalRate"
            rowField="prices.totalRateRaw"
            additionalCss={valueClass}
          >
            <AudiCopyS additionalClasses={labelClass}>
              {totalRateLabel}
              <FootnoteReference refId="nemo-fn_financing" inverted />
            </AudiCopyS>
          </AudiBasketConfigurationValueS>
        )}
        {showFinanceProductLink && (
          <AudiBasketConfigurationValueS
            additionalCss={`${valueClass} audi-basket-overview-prices__desktop-hide audi-basket-overview-prices__finance-product-wrapper`}
            field="financeProductName"
            rowField="financeProductName"
            footnoteRef="nemo-fn_financing"
            footnoteInverted
          >
            <AudiBasketFinanceLink
              additionalClasses="audi-basket__finance-product-link"
              variant="inverted"
            />
          </AudiBasketConfigurationValueS>
        )}
        {hasRate && (
          <AudiBasketRateCheckbox additionalClasses="audi-basket-rate-checkbox--inverted audi-basket-rate-checkbox--overview audi-basket-overview-prices__desktop-hide" />
        )}
      </div>
    </>
  );
};

export default React.forwardRef(AudiBasketOverviewPricesVariantA);
