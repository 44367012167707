import React, { createContext, useEffect, useMemo, useReducer } from 'react';

import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@volkswagen-onehub/audi-footnote-reference-service';
import { AudiMarketContextServiceV1 } from '@volkswagen-onehub/audi-market-context-service';

import { createSetFootnoteReferencesNumbersAction, FootnoteReferencesAction } from './actions';
import { footnoteReferenceReducer, FootnoteReferencesState } from './reducers';
import { BasketConfig } from '../../components/audi-basket/audi-basket';

type FootnoteReferencesContextProps = {
  state: FootnoteReferencesState;
  dispatch: React.Dispatch<FootnoteReferencesAction>;
};

export const FootnoteReferencesContext = createContext<FootnoteReferencesContextProps>(undefined);

interface FootnoteReferencesContextComponentsProps {
  footnoteReferenceServiceScopeManager: AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  marketContextService: AudiMarketContextServiceV1;
  scope?: string;
  config: BasketConfig;
}

export const FootnoteReferenceContextComponent: React.FC<
  FootnoteReferencesContextComponentsProps
> = ({ footnoteReferenceServiceScopeManager, marketContextService, scope, config, children }) => {
  const footnoteReferenceService = useMemo(() => {
    if (scope) {
      return footnoteReferenceServiceScopeManager.getCustomScopeRefService(scope);
    }

    return footnoteReferenceServiceScopeManager.getDefaultScopeRefService();
  }, [footnoteReferenceServiceScopeManager, scope]);

  const INITIAL_STATE: FootnoteReferencesState = {
    references: [],
    marketContextService,
    footnoteReferenceService,
    scope,
    ShowPriceFootnote: config.ShowPriceFootnote || false,
  };

  const [state, dispatch] = useReducer<
    React.Reducer<FootnoteReferencesState, FootnoteReferencesAction>
  >(footnoteReferenceReducer, INITIAL_STATE);

  useEffect(() => {
    footnoteReferenceService.registerCallback((footnoteReferences): void => {
      dispatch(createSetFootnoteReferencesNumbersAction(footnoteReferences));
    });

    return (): void => {
      footnoteReferenceService.unregisterCallback();
    };
  }, [footnoteReferenceService, scope]);

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <FootnoteReferencesContext.Provider value={{ state, dispatch }}>
      {scope && <div data-fefa-custom-id={scope}>{children}</div>}
      {!scope && children}
    </FootnoteReferencesContext.Provider>
  );
};
