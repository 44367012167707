import * as React from 'react';
import { useContext } from 'react';

import { AudiCopyS } from '@volkswagen-onehub/audi-core-ci-components';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';

import { useConfiguratorData } from '@oneaudi/configurator-data-context';
import { FootnoteReference } from '../../../utils/footnote-references/footnote-reference';
import {
  AudiBasketConfigurationValueM,
  AudiBasketConfigurationValueS,
} from '../../audi-basket-configuration-values/audi-basket-configuration-values';
import { messages } from '../../../utils/i18n/messages';
import AudiBasketOverviewPricesExtraPrices from '../../audi-basket-extra-prices/audi-basket-extra-prices';
import { createMobileViewExtendedAction } from '../../../utils/actions/basket-actions';
import { AudiBasketContext } from '../../audi-basket/audi-basket';
import { MarketVariantProps } from '../../audi-basket-price-details/audi-basket-price-details';
import { DeliveryCostsButton } from '../../audi-basket-price-details/delivery-costs/delivery-costs-button';
import AudiBasketFinanceLink from '../../audi-basket-finance-link/audi-basket-financing-link';
import AudiBasketIncentivesBlock from '../../audi-basket-incentives-block/audi-basket-incentives-block';

export type AudiBasketOverviewPricesVariantCProps = {
  displayFootnoteOnSecondLine?: boolean;
} & MarketVariantProps;

const AudiBasketOverviewPricesVariantC: React.ForwardRefRenderFunction<
  HTMLDivElement,
  AudiBasketOverviewPricesVariantCProps
> = ({ displayFootnoteOnSecondLine, showDeliveryCostsButton, layerUrl }) => {
  const { basketDispatch, basketState } = useContext(AudiBasketContext);
  const classPrefix = 'audi-basket-overview-prices__';
  const labelClass = `${classPrefix}price-label`;
  const valueClass = `${classPrefix}price-value`;
  const monthlyRateLabel = useI18n(messages.changeMonthlyRate);
  const financeProductName = useConfiguratorData('financeProductName');
  const {
    config: { FinanceLink },
    marketContextService,
  } = basketState;
  const showRateLink = Boolean(
    marketContextService?.hasEnvScope('BASKET_SHOW_FINCANCE_LINK') &&
      FinanceLink &&
      financeProductName,
  );

  return (
    <>
      <div className="audi-basket-overview-prices__base-prices audi-basket-overview-prices__desktop-hide">
        <AudiBasketConfigurationValueS
          field="prices.model"
          rowField="prices.modelRaw"
          additionalCss={valueClass}
        >
          <AudiCopyS additionalClasses={labelClass}>{useI18n(messages.basePrice)}</AudiCopyS>
        </AudiBasketConfigurationValueS>
        <AudiBasketConfigurationValueS
          field="prices.options"
          rowField="prices.optionsRaw"
          additionalCss={valueClass}
        >
          <AudiCopyS additionalClasses={labelClass}>{useI18n(messages.equipment)}</AudiCopyS>
        </AudiBasketConfigurationValueS>
        <AudiBasketOverviewPricesExtraPrices
          LabelComponent={AudiCopyS}
          ValueComponent={AudiBasketConfigurationValueS}
          ClassBase={classPrefix}
        />
      </div>
      <AudiBasketIncentivesBlock variant="mobile" tagName={AudiCopyS} />
      <AudiBasketConfigurationValueM
        additionalCss={`${valueClass} audi-basket__price-total`}
        additionalProps={{ bold: true }}
        field="prices.total"
        rowField="prices.totalRaw"
      >
        <AudiCopyS additionalClasses={labelClass}>
          {useI18n(messages.priceTotal)}
          {!displayFootnoteOnSecondLine ? (
            <FootnoteReference
              refId="nemo-fn_price"
              inverted
              onClick={(): void => basketDispatch(createMobileViewExtendedAction(false))}
            />
          ) : null}
        </AudiCopyS>
      </AudiBasketConfigurationValueM>
      <div className="audi-basket__finance-data">
        <AudiBasketConfigurationValueS
          field="prices.rotr"
          rowField="prices.rotrRaw"
          additionalCss={valueClass}
        >
          <AudiCopyS additionalClasses={`audi-copy--bold  ${labelClass}`}>
            {useI18n(messages.rotrPrice)}
            {displayFootnoteOnSecondLine ? (
              <FootnoteReference
                refId="nemo-fn_price"
                inverted
                onClick={(): void => basketDispatch(createMobileViewExtendedAction(false))}
              />
            ) : null}
          </AudiCopyS>
        </AudiBasketConfigurationValueS>
      </div>
      {showDeliveryCostsButton && (
        <DeliveryCostsButton
          additionalCss="audi-basket-overview-prices__delivery-cost audi-basket-overview-prices__desktop-hide"
          layerUrl={layerUrl}
        />
      )}
      {showRateLink && (
        <AudiBasketFinanceLink
          additionalClasses="audi-basket__finance-product-link audi-basket-overview-prices__desktop-hide"
          variant="inverted"
          textForLink={monthlyRateLabel}
        />
      )}
    </>
  );
};

export default React.forwardRef(AudiBasketOverviewPricesVariantC);
