import * as React from 'react';

import { AudiAudicodeServiceV1 } from '@volkswagen-onehub/audi-audicode-service';
import { AudiMarketContextServiceV1 } from '@volkswagen-onehub/audi-market-context-service';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@volkswagen-onehub/audi-footnote-reference-service';
import { ITrackingServiceV1 } from '@volkswagen-onehub/audi-tracking-service';

import { LayerManagerV25 } from '@volkswagen-onehub/layer-manager';
import { NumberFormatterServiceV1 } from '@oneaudi/number-formatter-service/dist/v1';
import { BasketActionTypes, IBasketAction, DisplayModeTypes } from '../actions/basket-actions';
import { BasketConfig } from '../../components/audi-basket/audi-basket';

export interface BasketState {
  audiCodeService?: AudiAudicodeServiceV1;
  marketContextService?: AudiMarketContextServiceV1;
  footnoteReferenceServiceScopeManager?: AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  config?: BasketConfig;
  extendedMobileView?: boolean;
  detailsLayerOpen?: boolean;
  displayMode?: string;
  trackingService?: ITrackingServiceV1;
  layerManager?: LayerManagerV25;
  localeService?: LocaleServiceV1;
  numberFormatterService?: NumberFormatterServiceV1;
}

export const basketReducer: React.Reducer<BasketState, IBasketAction> = (state, action) => {
  let { detailsLayerOpen, extendedMobileView } = state;

  switch (action.type) {
    case BasketActionTypes.SetConfig:
    case BasketActionTypes.SetMobileViewExtended:
    case BasketActionTypes.SetDetailsLayerOpen:
      return { ...state, ...action.payload };
    case BasketActionTypes.SetDisplayMode:
      if (state.displayMode !== action.payload.displayMode) {
        if (state.displayMode === DisplayModeTypes.Mobile) {
          extendedMobileView = false;
        } else {
          detailsLayerOpen = false;
        }
      }
      return { ...state, ...action.payload, extendedMobileView, detailsLayerOpen };
    default:
      return state;
  }
};
