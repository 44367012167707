import './audi-basket-price-details-link.css';
import * as React from 'react';
import { useContext } from 'react';

import { AudiCopyS } from '@volkswagen-onehub/audi-core-ci-components';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';

import { useConfiguratorData } from '@oneaudi/configurator-data-context';
import { AudiBasketContext } from '../audi-basket/audi-basket';
import { createDetailsLayerOpenAction } from '../../utils/actions/basket-actions';
import { messages } from '../../utils/i18n/messages';
import { doClickTracking, getTrackingObject } from '../../utils/helpers/tracking-helper';
import AudiBasketFinanceLink from '../audi-basket-finance-link/audi-basket-financing-link';

const AudiBasketPriceDetailsLink: React.FC = () => {
  const { basketDispatch, basketState } = useContext(AudiBasketContext);
  const {
    config: { FinanceLink },
    trackingService,
    marketContextService,
  } = basketState;

  const priceDetailsLabel = useI18n(messages.priceDetails);
  const monthlyRateLabel = useI18n(messages.changeMonthlyRate);
  const financeProductName = useConfiguratorData('financeProductName');
  const showRateLink = Boolean(
    marketContextService?.hasEnvScope('BASKET_SHOW_FINCANCE_LINK') &&
      FinanceLink &&
      financeProductName,
  );

  const handleClickTracking = (): void => {
    if (trackingService) {
      const trackingObject = getTrackingObject({
        label: priceDetailsLabel,
        eventName: 'price details',
      });
      doClickTracking(trackingObject, trackingService);
    }
  };

  return (
    <div className="audi-basket__price-button-div">
      <button
        type="button"
        className="audi-basket__price-button-layer"
        onClick={(): void => {
          if (basketDispatch) {
            basketDispatch(createDetailsLayerOpenAction(true));
          }
          handleClickTracking();
        }}
      >
        <AudiCopyS>{priceDetailsLabel}</AudiCopyS>
      </button>
      {showRateLink && (
        <AudiBasketFinanceLink
          additionalClasses="audi-basket__finance-product-link"
          variant="inverted"
          textForLink={monthlyRateLabel}
        />
      )}
    </div>
  );
};

export default AudiBasketPriceDetailsLink;
