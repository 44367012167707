import { MutableRefObject, ReactElement, useCallback, useRef } from 'react';

import * as ReactDom from 'react-dom';

interface UsePortalValue {
  Portal: ({ children }) => ReactElement;
  portalRef: MutableRefObject<HTMLElement>;
}

export const usePortal = (className?: string): UsePortalValue => {
  const container = useRef<HTMLElement>(null);

  if (!container.current) {
    container.current = document.createElement('div');
    if (className) {
      container.current.className = className;
    }
    document.body.appendChild(container.current);
  }

  const Portal = useCallback(({ children }): ReactElement => {
    return ReactDom.createPortal(children, container.current);
  }, []);

  return {
    Portal,
    portalRef: container,
  };
};
