import * as React from 'react';
import { useContext } from 'react';

import { AudiCopyM, AudiCopyS } from '@volkswagen-onehub/audi-core-ci-components';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';

import { FootnoteReference } from '../../utils/footnote-references/footnote-reference';
import { messages } from '../../utils/i18n/messages';
import {
  AudiBasketConfigurationValueS,
  AudiBasketConfigurationValueM,
} from '../audi-basket-configuration-values/audi-basket-configuration-values';
import { createMobileViewExtendedAction } from '../../utils/actions/basket-actions';
import { AudiBasketContext } from '../audi-basket/audi-basket';

type ValueComponents = typeof AudiBasketConfigurationValueS;
type LabelComponents = typeof AudiCopyS;

interface ExtraPricesProps {
  ValueComponent: ValueComponents;
  LabelComponent: LabelComponents;
  ClassBase: 'audi-basket-overview-prices__' | 'audi-basket-price-details__';
  inverted?: boolean;
  hideFootnoteOnTaxDetailsLine?: boolean;
  showVatTaxExtraForBev?: boolean;
}

const AudiBasketOverviewPricesExtraPrices: React.FC<ExtraPricesProps> = ({
  ValueComponent = AudiBasketConfigurationValueM,
  LabelComponent = AudiCopyM,
  ClassBase,
  inverted = false,
  hideFootnoteOnTaxDetailsLine = false,
  showVatTaxExtraForBev = false,
}) => {
  const { basketDispatch } = useContext(AudiBasketContext);

  const labelClass = `${ClassBase}price-label`;
  const valueClass = `${ClassBase}price-value`;

  return (
    <>
      <ValueComponent
        field="prices.charges"
        rowField="prices.chargesRaw"
        additionalCss={valueClass}
      >
        <LabelComponent additionalClasses={labelClass}>
          {useI18n(messages.destinationCharge)}
        </LabelComponent>
      </ValueComponent>

      <ValueComponent
        field="prices.audiCare"
        checkValueField="prices.audiCareRaw"
        additionalCss={valueClass}
      >
        <LabelComponent additionalClasses={labelClass}>{useI18n(messages.audiCare)}</LabelComponent>
      </ValueComponent>

      <ValueComponent
        field="prices.dealerAdminFee"
        rowField="prices.dealerAdminFeeRaw"
        additionalCss={valueClass}
      >
        <LabelComponent additionalClasses={labelClass}>
          {useI18n(messages.dealerAdminFee)}
        </LabelComponent>
      </ValueComponent>

      <ValueComponent
        field="prices.airConditioningLevy"
        rowField="prices.airConditioningLevyRaw"
        additionalCss={valueClass}
      >
        <LabelComponent additionalClasses={labelClass}>
          {useI18n(messages.airConditioningLevy)}
        </LabelComponent>
      </ValueComponent>

      <ValueComponent
        field="prices.greenLevy"
        rowField="prices.greenLevyRaw"
        additionalCss={valueClass}
      >
        <LabelComponent additionalClasses={labelClass}>
          {useI18n(messages.greenLevy)}
        </LabelComponent>
      </ValueComponent>

      <ValueComponent
        field="prices.audiCare"
        rowField="prices.audiCareRaw"
        additionalCss={valueClass}
      >
        <LabelComponent additionalClasses={labelClass}>{useI18n(messages.audiCare)}</LabelComponent>
      </ValueComponent>

      <ValueComponent
        field="prices.estimatedMotorVehicleCounselFee"
        rowField="prices.estimatedMotorVehicleCounselFeeRaw"
        additionalCss={valueClass}
      >
        <LabelComponent additionalClasses={labelClass}>
          {useI18n(messages.estimatedMotorVehicleCounselFee)}
        </LabelComponent>
      </ValueComponent>

      <ValueComponent
        field="prices.luxuryTax"
        rowField="prices.luxuryTaxRaw"
        additionalCss={valueClass}
      >
        <LabelComponent additionalClasses={labelClass}>
          {useI18n(messages.luxuryTax)}
        </LabelComponent>
      </ValueComponent>

      <ValueComponent
        field="prices.nettoBase"
        rowField="prices.nettoBaseRaw"
        additionalCss={valueClass}
      >
        <LabelComponent additionalClasses={labelClass}>
          {useI18n(messages.basePriceNetto)}
        </LabelComponent>
      </ValueComponent>

      <ValueComponent field="prices.bpm" rowField="prices.bpmRaw" additionalCss={valueClass}>
        <LabelComponent additionalClasses={labelClass}>{useI18n(messages.bpmTax)}</LabelComponent>
      </ValueComponent>

      <ValueComponent field="prices.vat" rowField="prices.vatRaw" additionalCss={valueClass}>
        <LabelComponent additionalClasses={labelClass}>{useI18n(messages.vat)}</LabelComponent>
      </ValueComponent>
      {showVatTaxExtraForBev ? (
        <>
          <ValueComponent
            field="prices.taxTotalWithoutVat"
            rowField="prices.taxTotalWithoutVatRaw"
            additionalCss={valueClass}
          >
            <LabelComponent additionalClasses={labelClass}>
              {useI18n(messages.taxTotalWithoutVat)}
            </LabelComponent>
          </ValueComponent>
          <ValueComponent
            field="prices.evVatTotal"
            rowField="prices.evVatTotalRaw"
            additionalCss={valueClass}
          >
            <LabelComponent additionalClasses={labelClass}>
              {useI18n(messages.evVatTotal)}
            </LabelComponent>
          </ValueComponent>
        </>
      ) : (
        <ValueComponent
          field="prices.taxTotal"
          rowField="prices.taxTotalRaw"
          additionalCss={valueClass}
        >
          <LabelComponent additionalClasses={labelClass}>
            {useI18n(messages.taxTotal)}
            {!hideFootnoteOnTaxDetailsLine && (
              <FootnoteReference
                refId="nemo-fn_eec-disclaimer"
                inverted={inverted}
                onClick={(): void => basketDispatch(createMobileViewExtendedAction(false))}
              />
            )}
          </LabelComponent>
        </ValueComponent>
      )}
    </>
  );
};

export default AudiBasketOverviewPricesExtraPrices;
