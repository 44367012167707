import './audi-basket-overview-actions.css';

import * as React from 'react';

import AudiBasketCta from '../audi-basket-cta/audi-basket-cta';
import AudiBasketHandrail from '../audi-basket-handrail/audi-basket-handrail';

const AudiBasketOverviewActions: React.ForwardRefRenderFunction<HTMLDivElement> = (props, ref) => {
  return (
    <div className="audi-basket-overview-button" ref={ref}>
      <AudiBasketHandrail />
      <AudiBasketCta />
    </div>
  );
};

export default React.forwardRef(AudiBasketOverviewActions);
