import * as React from 'react';
import { useContext } from 'react';

import { FeatureAppLoader } from '@feature-hub/react';

import { AudiBasketContext, fefa } from '../audi-basket/audi-basket';
import './audi-basket-price-details-layer-footnotes.css';

const AudiBasketPriceDetailsLayerFootnotes: React.FC = () => {
  const { basketState } = useContext(AudiBasketContext);
  const {
    config: { OneFootnoteDataUrl },
  } = basketState;

  return OneFootnoteDataUrl ? (
    <div className="audi-basket-price-details-layer-footnotes">
      <FeatureAppLoader
        featureAppId="audi-basket-fefa-layer"
        baseUrl={`${fefa.url}/${fefa.version}/`}
        src="fh/app.js"
        config={{
          apiUrls: [OneFootnoteDataUrl],
          layerElementClassName: 'audi-basket_price-details-layer__content',
        }}
      />
    </div>
  ) : null;
};

export default AudiBasketPriceDetailsLayerFootnotes;
