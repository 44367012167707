import * as React from 'react';

import { AudiMarketContextServiceV1 } from '@volkswagen-onehub/audi-market-context-service';
import { AudiFootnoteReferenceServiceInterfaceV3 } from '@volkswagen-onehub/audi-footnote-reference-service';
// eslint-disable-next-line import/no-unresolved
import { EnumerableFootnote } from '@volkswagen-onehub/audi-footnote-reference-service/dist/store/v2/footnote-reference-store';

import { FootnoteReferencesAction, FootnoteReferencesActionTypes } from './actions';

export interface Reference {
  id: string;
  counter?: number;
  contextID?: string;
  number?: number;
}

export interface FootnoteReferencesState {
  references: Reference[];
  marketContextService: AudiMarketContextServiceV1;
  footnoteReferenceService: AudiFootnoteReferenceServiceInterfaceV3;
  scope: string;
  ShowPriceFootnote: boolean;
}

const updateReferences = (
  state: FootnoteReferencesState,
  refId: string,
  change = 1,
): Reference[] => {
  const { references: existingReferences } = state;

  if (!existingReferences.find((reference) => reference.id === refId)) {
    existingReferences.push({
      id: refId,
      counter: 0,
    });
  }

  const newReferences = existingReferences.reduce<Reference[]>((references, reference) => {
    if (reference.id === refId) {
      reference.counter += change;
    }

    return [...references, reference];
  }, []);

  return newReferences.filter((reference) => reference.counter > 0);
};

const updateReferenceServiceData = (footnoteReferenceService, references): void => {
  footnoteReferenceService.removeFootnoteReferences();
  footnoteReferenceService.addFootnoteReferences(references.map((reference) => reference.id));
};

const setReferenceNumbers = (
  existingRefs: Reference[],
  numberedRefs: EnumerableFootnote[],
): Reference[] => {
  return existingRefs.map((reference) => {
    const numberedRef = numberedRefs.find(
      (numberedReference) => numberedReference.id === reference.id,
    );
    const number = numberedRef ? numberedRef.number : -1;

    return {
      ...reference,
      contextID: numberedRef?.contextID,
      number,
    };
  });
};

const checkScopedFootnotes = (
  marketContextService: AudiMarketContextServiceV1,
  refId: string,
  showPriceFootnote: boolean,
): boolean => {
  switch (refId) {
    case 'nemo-fn_eec-disclaimer':
      return marketContextService.hasEnvScope('EEC_FOOTNOTES');
    case 'nemo-fn_financing':
      return marketContextService.hasEnvScope('FINANCING');
    case 'nemo-fn_price':
      return showPriceFootnote;
    default:
      return true;
  }
};

export const footnoteReferenceReducer: React.Reducer<
  FootnoteReferencesState,
  FootnoteReferencesAction
> = (state, action) => {
  switch (action.type) {
    case FootnoteReferencesActionTypes.AddFootnoteReference: {
      if (
        !checkScopedFootnotes(state.marketContextService, action.payload, state.ShowPriceFootnote)
      ) {
        return { ...state };
      }

      const newReferences = updateReferences(state, action.payload);
      updateReferenceServiceData(state.footnoteReferenceService, newReferences);

      return { ...state, references: newReferences };
    }
    case FootnoteReferencesActionTypes.RemoveFootnoteReference: {
      const newReferences = updateReferences(state, action.payload, -1);
      updateReferenceServiceData(state.footnoteReferenceService, newReferences);

      return { ...state, references: newReferences };
    }
    case FootnoteReferencesActionTypes.SetFootnoteReferencesNumbers: {
      const newReferences = setReferenceNumbers(state.references, action.payload);

      return { ...state, references: newReferences };
    }
    default: {
      return state;
    }
  }
};
