import React, { useContext } from 'react';

import './audi-basket-incentives-block.css';
import { useConfiguratorData } from '@oneaudi/configurator-data-context';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import {
  AudiBasketConfigurationValueH3,
  AudiBasketConfigurationValueS,
  ValueComponents,
} from '../audi-basket-configuration-values/audi-basket-configuration-values';
import { AudiBasketContext } from '../audi-basket/audi-basket';
import { messages } from '../../utils/i18n/messages';

interface Incentive {
  actionTitle: string;
  actionId: string;
  status: string;
  value: number;
}

type IncentivesVariant = 'mobile' | 'desktop';

interface IncentivesBlockProps {
  variant: IncentivesVariant;
  tagName: ValueComponents;
}

const AudiBasketIncentivesBlock: React.FC<IncentivesBlockProps> = ({
  tagName: CopyTag,
  variant,
}) => {
  const { basketState } = useContext(AudiBasketContext);
  const numberFormatterService = basketState?.numberFormatterService;
  const subtotalPrice = useConfiguratorData<string>('prices.totalWithoutIncentives');
  const incentives = useConfiguratorData<Incentive[]>('incentives');

  const formatPrice = (value: number) => {
    const checkValue = value ? numberFormatterService?.formatCurrency(value) : undefined;
    return checkValue;
  };
  const subtotalLabel = useI18n(messages.subtotal);

  if (!incentives || !basketState) return null;

  const selectedIncentives = incentives?.filter((incentive) => incentive?.status === '11010');
  if (selectedIncentives?.length < 1) {
    return null;
  }

  const wrapperClassName =
    variant === 'mobile'
      ? 'audi-basket-overview-prices__base-prices audi-basket-overview-prices__desktop-hide'
      : 'audi-basket-incentives-block__desktop-wrapper';
  const labelClassName =
    variant === 'mobile'
      ? 'audi-basket-overview-prices__price-label'
      : 'audi-basket-price-details__price-label';
  const valueClassName = `audi-basket-configuration-value ${
    variant === 'mobile'
      ? 'audi-basket-overview-prices__price-value'
      : 'audi-basket-price-details__price-value'
  }`;

  const subtotalPriceField = subtotalPrice ? 'prices.totalWithoutIncentives' : 'prices.total';
  const checkValueTotalPriceField = subtotalPrice
    ? 'prices.totalWithoutIncentivesRaw'
    : 'prices.totalRaw';
  const subTotalPriceComponent =
    variant === 'mobile' ? (
      <AudiBasketConfigurationValueS
        field={subtotalPriceField}
        rowField={checkValueTotalPriceField}
        additionalCss={valueClassName}
      >
        <CopyTag additionalClasses={labelClassName}>{subtotalLabel}</CopyTag>
      </AudiBasketConfigurationValueS>
    ) : (
      <AudiBasketConfigurationValueH3
        field={subtotalPriceField}
        rowField={checkValueTotalPriceField}
        additionalCss={valueClassName}
      >
        <CopyTag additionalClasses={labelClassName}>{subtotalLabel}</CopyTag>
      </AudiBasketConfigurationValueH3>
    );

  return (
    <div className={wrapperClassName}>
      {subTotalPriceComponent}
      {sortIncentives(selectedIncentives).map((incentive) => (
        <div className={valueClassName} key={incentive.actionId}>
          <CopyTag additionalClasses={labelClassName}>{incentive.actionTitle}</CopyTag>
          <CopyTag>
            <span className="audi-basket-configuration-value__label">
              {formatPrice(incentive.value)}
            </span>
          </CopyTag>
        </div>
      ))}
    </div>
  );
};

export default AudiBasketIncentivesBlock;

export const sortIncentives = (data: Incentive[]) => {
  const numericTitles = data.filter((item) => startsWithNumber(item.actionTitle));
  const alphabeticTitles = data.filter((item) => !startsWithNumber(item.actionTitle));

  // localeCompare is used for special characterslike 'Ü' etc.
  alphabeticTitles.sort((a, b) => a.actionTitle.localeCompare(b.actionTitle));
  numericTitles.sort((a, b) =>
    a.actionTitle.localeCompare(b.actionTitle, undefined, { numeric: true }),
  );

  return alphabeticTitles.concat(numericTitles);
};

function startsWithNumber(title: string) {
  return /^\d/.test(title);
}
