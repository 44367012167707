import * as React from 'react';

import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { useConfiguratorData } from '@oneaudi/configurator-data-context';
import {
  AudiHeadlineOrder2,
  AudiHeadlineOrder3,
  AudiCopyM,
} from '@volkswagen-onehub/audi-core-ci-components';

import { FootnoteReference } from '../../../utils/footnote-references/footnote-reference';
import {
  AudiBasketConfigurationValueH3,
  AudiBasketConfigurationValueM,
} from '../../audi-basket-configuration-values/audi-basket-configuration-values';
import { messages } from '../../../utils/i18n/messages';
import AudiBasketOverviewPricesExtraPrices from '../../audi-basket-extra-prices/audi-basket-extra-prices';
import { MarketVariantProps } from '../audi-basket-price-details';
import { DeliveryCostsButton } from '../delivery-costs/delivery-costs-button';
import AudiBasketIncentivesBlock from '../../audi-basket-incentives-block/audi-basket-incentives-block';

export type AudiBasketPriceDetailsVariantCProps = {
  displayFootnoteOnSecondLine?: boolean;
} & MarketVariantProps;

const AudiBasketPriceDetailsVariantC: React.FC<AudiBasketPriceDetailsVariantCProps> = ({
  displayFootnoteOnSecondLine,
  showDeliveryCostsButton,
  layerUrl,
}) => {
  const classPrefix = 'audi-basket-price-details__';
  const labelClass = `${classPrefix}price-label`;
  const valueClass = `${classPrefix}price-value`;

  const carlineNamePrefix = useI18n(messages.yourCarline);
  const carlineName = useConfiguratorData<string>('carlineName')?.trim();
  const engineName = useConfiguratorData<string>('description')?.trim();

  return (
    <>
      {carlineName && (
        <AudiHeadlineOrder2 additionalClasses={`${classPrefix}carline`}>
          {carlineNamePrefix} {carlineName}
        </AudiHeadlineOrder2>
      )}
      {engineName && (
        <AudiHeadlineOrder3 additionalClasses={`${classPrefix}subline`}>
          {engineName}
          <FootnoteReference refId="nemo-fn_eec-disclaimer" />
        </AudiHeadlineOrder3>
      )}

      <AudiBasketConfigurationValueM
        field="prices.model"
        rowField="prices.modelRaw"
        additionalCss={valueClass}
      >
        <AudiCopyM additionalClasses={labelClass}>{useI18n(messages.basePrice)}</AudiCopyM>
      </AudiBasketConfigurationValueM>
      <AudiBasketConfigurationValueM
        field="prices.options"
        rowField="prices.optionsRaw"
        additionalCss={valueClass}
      >
        <AudiCopyM additionalClasses={labelClass}>{useI18n(messages.equipment)}</AudiCopyM>
      </AudiBasketConfigurationValueM>

      <AudiBasketOverviewPricesExtraPrices
        LabelComponent={AudiCopyM}
        ValueComponent={AudiBasketConfigurationValueM}
        ClassBase={classPrefix}
      />
      <AudiBasketIncentivesBlock variant="desktop" tagName={AudiCopyM} />

      <div className={`${classPrefix}divider`} />

      <AudiBasketConfigurationValueH3
        field="prices.total"
        rowField="prices.totalRaw"
        additionalCss={valueClass}
      >
        <AudiCopyM additionalClasses={labelClass}>
          {useI18n(messages.priceTotal)}
          {!displayFootnoteOnSecondLine ? <FootnoteReference refId="nemo-fn_price" /> : null}
        </AudiCopyM>
      </AudiBasketConfigurationValueH3>
      {showDeliveryCostsButton && <DeliveryCostsButton layerUrl={layerUrl} />}
      <AudiBasketConfigurationValueM
        field="prices.rotr"
        rowField="prices.rotrRaw"
        additionalCss={valueClass}
      >
        <AudiCopyM additionalClasses={`audi-copy--bold  ${labelClass}`}>
          {useI18n(messages.rotrPrice)}
          {displayFootnoteOnSecondLine ? <FootnoteReference refId="nemo-fn_price" /> : null}
        </AudiCopyM>
      </AudiBasketConfigurationValueM>
    </>
  );
};

export default AudiBasketPriceDetailsVariantC;
