import * as React from 'react';
import { AnchorHTMLAttributes, useContext, useEffect, useState } from 'react';

import { FootnoteReferencesContext } from './footnote-references-context';
import { createAddFootnoteReferenceAction, createRemoveFootnoteReferenceAction } from './actions';
import { Reference } from './reducers';

interface FootnoteReferenceProps {
  refId: string;
  inverted?: boolean;
  onClick?: AnchorHTMLAttributes<HTMLAnchorElement>['onClick'];
}

export const FootnoteReference: React.FC<FootnoteReferenceProps> = ({
  refId,
  inverted = false,
  onClick,
}) => {
  const { state, dispatch } = useContext(FootnoteReferencesContext);
  const [reference, setReference] = useState<Reference>(undefined);
  const invertedClass = inverted ? ' audi-link--inverted' : '';

  useEffect(() => {
    if (refId) {
      dispatch(createAddFootnoteReferenceAction(refId));
    }

    return (): void => {
      dispatch(createRemoveFootnoteReferenceAction(refId));
    };
  }, [dispatch, refId]);

  useEffect(() => {
    const currentReference = state.references.find((ref) => ref.id === refId);

    setReference(currentReference?.number > 0 ? currentReference : undefined);
  }, [refId, state.references]);

  return reference ? (
    <a
      className={`${invertedClass} audi-j-footnote-reference`}
      href={`#${reference.contextID ? reference.contextID : reference.id}`}
      onClick={onClick}
    >
      <sup className="audi-footnote-anchor__text">{reference.number}</sup>
    </a>
  ) : null;
};
