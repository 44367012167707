import * as React from 'react';
import { useEffect, useCallback, useContext } from 'react';

import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { createDetailsLayerOpenAction } from '../../utils/actions/basket-actions';
import { AudiBasketContext } from '../audi-basket/audi-basket';
import AudiBasketPriceDetails from '../audi-basket-price-details/audi-basket-price-details';
import { FootnoteReferenceContextComponent } from '../../utils/footnote-references/footnote-references-context';

import './audi-basket-price-details-layer.css';
import { messages } from '../../utils/i18n/messages';

interface AudiBasketPriceDetailsLayerProps {
  deliveryCostsLayerUrl: string;
}

const AudiBasketPriceDetailsLayer: React.FC<AudiBasketPriceDetailsLayerProps> = ({
  deliveryCostsLayerUrl,
}) => {
  const { basketState, basketDispatch } = useContext(AudiBasketContext);
  const { config } = basketState;

  useEffect(() => {
    document?.querySelector('body').classList.add('u-audi-basket-detail-layer-opened');
    return (): void => {
      document?.querySelector('body').classList.remove('u-audi-basket-detail-layer-opened');
    };
  }, []);

  const closeLayer = useCallback((): void => {
    basketDispatch(createDetailsLayerOpenAction(false));
  }, [basketDispatch]);

  return (
    <div className="audi-basket_price-details-layer">
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <div
        className="audi-basket_price-details-layer__shader"
        onClick={closeLayer}
        onKeyDown={closeLayer}
        role="button"
        tabIndex={0}
      />
      <div className="audi-basket_price-details-layer__content" data-fefa-layer-active="true">
        <div className="audi-basket_price-details-layer__close_button">
          <button aria-label={useI18n(messages.closeLayer)} onClick={closeLayer} type="button">
            <svg viewBox="0 0 48 48" className="audi-basket_price-details-layer__close_button-icon">
              <path
                d="M31.6,15.4 L15.4,31.6 M15.4,15.4 L31.7,31.7"
                stroke="currentColor"
                strokeWidth="1"
                fillRule="evenodd"
              />
            </svg>
          </button>
        </div>
        <FootnoteReferenceContextComponent
          marketContextService={basketState.marketContextService}
          footnoteReferenceServiceScopeManager={basketState.footnoteReferenceServiceScopeManager}
          scope="audi-basket-layer"
          config={config}
        >
          <AudiBasketPriceDetails deliveryCostsLayerUrl={deliveryCostsLayerUrl} />
        </FootnoteReferenceContextComponent>
      </div>
    </div>
  );
};

export default AudiBasketPriceDetailsLayer;
