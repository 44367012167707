import * as React from 'react';
import { useContext } from 'react';

import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { useConfiguratorData } from '@oneaudi/configurator-data-context';
import {
  AudiHeadlineOrder2,
  AudiHeadlineOrder3,
  AudiCopyM,
} from '@volkswagen-onehub/audi-core-ci-components';

import { FootnoteReference } from '../../../utils/footnote-references/footnote-reference';
import {
  AudiBasketConfigurationValueS,
  AudiBasketConfigurationValueH3,
  AudiBasketConfigurationValueM,
} from '../../audi-basket-configuration-values/audi-basket-configuration-values';
import { messages } from '../../../utils/i18n/messages';
import AudiBasketFinanceLink from '../../audi-basket-finance-link/audi-basket-financing-link';
import { AudiBasketContext } from '../../audi-basket/audi-basket';
import AudiBasketRateCheckbox from '../../audi-basket-rate-checkbox/audi-basket-rate-checkbox';
import AudiBasketOverviewPricesExtraPrices from '../../audi-basket-extra-prices/audi-basket-extra-prices';
import { MarketVariantProps } from '../audi-basket-price-details';
import { DeliveryCostsButton } from '../delivery-costs/delivery-costs-button';
import AudiBasketIncentivesBlock from '../../audi-basket-incentives-block/audi-basket-incentives-block';

const AudiBasketPriceDetailsVariantB: React.ForwardRefRenderFunction<
  HTMLDivElement,
  MarketVariantProps
> = ({ showDeliveryCostsButton, layerUrl }) => {
  const { basketState } = useContext(AudiBasketContext);
  const {
    config: { FinanceLink },
  } = basketState;

  const classPrefix = 'audi-basket-price-details__';
  const labelClass = `${classPrefix}price-label`;
  const valueClass = `${classPrefix}price-value`;

  const hasRate = useConfiguratorData<number>('prices.totalRateRaw') > 0;
  const showFinanceProductLink = hasRate && FinanceLink;

  const totalRateLabel = useI18n(messages.totalRate);
  const carlineNamePrefix = useI18n(messages.yourCarline);
  const carlineName = useConfiguratorData<string>('carlineName')?.trim();
  const engineName = useConfiguratorData<string>('description')?.trim();
  return (
    <>
      {carlineName && (
        <AudiHeadlineOrder2 additionalClasses={`${classPrefix}carline`}>
          {carlineNamePrefix} {carlineName}
        </AudiHeadlineOrder2>
      )}
      {engineName && (
        <AudiHeadlineOrder3 additionalClasses={`${classPrefix}subline`}>
          {engineName}
          <FootnoteReference refId="nemo-fn_eec-disclaimer" />
        </AudiHeadlineOrder3>
      )}

      <AudiBasketConfigurationValueM
        field="prices.model"
        rowField="prices.modelRaw"
        additionalCss={valueClass}
      >
        <AudiCopyM additionalClasses={labelClass}>{useI18n(messages.basePrice)}</AudiCopyM>
      </AudiBasketConfigurationValueM>
      <AudiBasketConfigurationValueM
        field="prices.options"
        rowField="prices.optionsRaw"
        additionalCss={valueClass}
      >
        <AudiCopyM additionalClasses={labelClass}>{useI18n(messages.equipment)}</AudiCopyM>
      </AudiBasketConfigurationValueM>

      <AudiBasketOverviewPricesExtraPrices
        LabelComponent={AudiCopyM}
        ValueComponent={AudiBasketConfigurationValueM}
        ClassBase={classPrefix}
      />
      <AudiBasketIncentivesBlock variant="desktop" tagName={AudiCopyM} />

      <div className={`${classPrefix}divider`} />

      <AudiBasketConfigurationValueH3
        field="prices.total"
        rowField="prices.totalRaw"
        additionalCss={valueClass}
      >
        <AudiCopyM additionalClasses={labelClass}>{useI18n(messages.priceTotal)}</AudiCopyM>
      </AudiBasketConfigurationValueH3>
      {showDeliveryCostsButton && <DeliveryCostsButton layerUrl={layerUrl} />}
      <AudiBasketConfigurationValueM
        field="prices.rotrRate"
        rowField="prices.rotrRateRaw"
        additionalCss={valueClass}
      >
        <AudiCopyM additionalClasses={` ${labelClass}`}>{useI18n(messages.rotrRate)}</AudiCopyM>
      </AudiBasketConfigurationValueM>
      <AudiBasketConfigurationValueM
        field="prices.rotr"
        rowField="prices.rotrRaw"
        additionalCss={valueClass}
        additionalProps={{ bold: true }}
      >
        <AudiCopyM additionalClasses={`audi-copy--bold  ${labelClass}`}>
          {useI18n(messages.rotrPrice)}
          <FootnoteReference refId="nemo-fn_price" />
        </AudiCopyM>
      </AudiBasketConfigurationValueM>
      {hasRate && (
        <AudiBasketConfigurationValueM
          field="prices.totalRate"
          rowField="prices.totalRateRaw"
          additionalCss={`${valueClass} ${valueClass}--rate`}
        >
          <AudiCopyM additionalClasses={labelClass}>
            {totalRateLabel}
            <FootnoteReference refId="nemo-fn_financing" inverted />
          </AudiCopyM>
        </AudiBasketConfigurationValueM>
      )}
      {showFinanceProductLink && (
        <AudiBasketConfigurationValueS
          field="financeProductName"
          rowField="financeProductName"
          additionalCss={`${valueClass} ${valueClass}--financing-product`}
          footnoteRef="nemo-fn_financing"
        >
          <AudiBasketFinanceLink additionalClasses={`${labelClass} ${labelClass}--financing`} />
        </AudiBasketConfigurationValueS>
      )}
      {hasRate && <AudiBasketRateCheckbox />}
    </>
  );
};

export default React.forwardRef(AudiBasketPriceDetailsVariantB);
