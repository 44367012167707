import { MarketVariants } from './audi-basket';
import { DisplayModeTypes } from '../../utils/actions/basket-actions';

export function getDisplayMode(
  contentWidth: number,
  maxWidth: number,
  carInfoWidth: number,
  marketVariant: MarketVariants = 'A',
): DisplayModeTypes {
  if (contentWidth > maxWidth) {
    if (contentWidth - carInfoWidth > maxWidth || marketVariant === 'G') {
      return DisplayModeTypes.Mobile;
    }
    return DisplayModeTypes.Tablet;
  }
  return DisplayModeTypes.Desktop;
}
