import * as React from 'react';
import { useContext } from 'react';

import { AudiLinkS, AudiLinkVariant } from '@volkswagen-onehub/audi-core-ci-components';

import { AudiBasketContext } from '../audi-basket/audi-basket';
import { useLinkTarget } from '../../utils/hooks/use-link-target';

interface AudiBasketFinanceLinkProps {
  additionalClasses?: string;
  variant?: AudiLinkVariant;
  textForLink?: string;
}

const AudiBasketFinanceLink: React.FC<AudiBasketFinanceLinkProps> = ({
  additionalClasses,
  variant,
  textForLink,
}) => {
  const { basketState } = useContext(AudiBasketContext);
  const {
    config: { FinanceLink },
  } = basketState;
  const { LinkTarget, LinkUrl, LinkText } = FinanceLink;
  const { className, targetParam } = useLinkTarget(LinkTarget);

  return (
    <AudiLinkS
      additionalClasses={`${className} ${additionalClasses || ''}`}
      href={LinkUrl}
      layerTemplate="form"
      variant={variant}
      target={targetParam}
      underlined
    >
      {textForLink ?? LinkText}
    </AudiLinkS>
  );
};

export default AudiBasketFinanceLink;
