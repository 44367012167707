import * as React from 'react';
import { useContext } from 'react';

import { AudiCopyS } from '@volkswagen-onehub/audi-core-ci-components';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { useConfiguratorData } from '@oneaudi/configurator-data-context';

import { FootnoteReference } from '../../../utils/footnote-references/footnote-reference';
import {
  AudiBasketConfigurationValueM,
  AudiBasketConfigurationValueS,
} from '../../audi-basket-configuration-values/audi-basket-configuration-values';
import { messages } from '../../../utils/i18n/messages';
import AudiBasketOverviewPricesExtraPrices from '../../audi-basket-extra-prices/audi-basket-extra-prices';
import { createMobileViewExtendedAction } from '../../../utils/actions/basket-actions';
import { AudiBasketContext } from '../../audi-basket/audi-basket';
import { MarketVariantProps } from '../../audi-basket-price-details/audi-basket-price-details';
import { DeliveryCostsButton } from '../../audi-basket-price-details/delivery-costs/delivery-costs-button';
import AudiBasketIncentivesBlock from '../../audi-basket-incentives-block/audi-basket-incentives-block';

const AudiBasketOverviewPricesVariantF: React.ForwardRefRenderFunction<
  HTMLDivElement,
  MarketVariantProps
> = ({ showDeliveryCostsButton, layerUrl }) => {
  const { basketDispatch } = useContext(AudiBasketContext);

  const classPrefix = 'audi-basket-overview-prices__';
  const labelClass = `${classPrefix}price-label`;
  const valueClass = `${classPrefix}price-value`;

  const taxableBenefit1 = useConfiguratorData<string>('prices.taxableBenefit1');
  const taxableBenefit2 = useConfiguratorData<string>('prices.taxableBenefit2');
  const showTaxableBenefits =
    (taxableBenefit1 && taxableBenefit1 !== '') || (taxableBenefit2 && taxableBenefit2 !== '');
  const companyCarLabel = useI18n(messages.companyCar);
  const taxableBenefit1Label = useI18n(messages.taxableBenefit1);
  const taxableBenefit2Label = useI18n(messages.taxableBenefit2);

  return (
    <>
      <div className="audi-basket-overview-prices__base-prices audi-basket-overview-prices__desktop-hide">
        <AudiBasketOverviewPricesExtraPrices
          LabelComponent={AudiCopyS}
          ValueComponent={AudiBasketConfigurationValueS}
          ClassBase={classPrefix}
          inverted
        />

        <AudiBasketConfigurationValueS
          field="prices.nettoOptions"
          rowField="prices.nettoOptionsRaw"
          additionalCss={`${valueClass} audi-basket-overview-prices__desktop-hide`}
        >
          <AudiCopyS additionalClasses={` ${labelClass}`}>
            {useI18n(messages.optionsPriceNetto)}
          </AudiCopyS>
        </AudiBasketConfigurationValueS>
      </div>
      <AudiBasketIncentivesBlock variant="mobile" tagName={AudiCopyS} />
      <AudiBasketConfigurationValueM
        additionalCss={`${valueClass} audi-basket__price-total`}
        additionalProps={{ bold: true }}
        field="prices.total"
        rowField="prices.totalRaw"
      >
        <AudiCopyS additionalClasses={labelClass}>
          {useI18n(messages.priceTotal)}
          <FootnoteReference
            refId="nemo-fn_price"
            inverted
            onClick={(): void => basketDispatch(createMobileViewExtendedAction(false))}
          />
        </AudiCopyS>
      </AudiBasketConfigurationValueM>
      {showDeliveryCostsButton && (
        <DeliveryCostsButton
          additionalCss="audi-basket-overview-prices__delivery-cost audi-basket-overview-prices__desktop-hide"
          layerUrl={layerUrl}
        />
      )}
      {showTaxableBenefits && (
        <div className="audi-basket__company-car-information">
          <AudiCopyS additionalClasses={`${valueClass} ${classPrefix}company-car-information`}>
            {companyCarLabel}
          </AudiCopyS>

          <AudiBasketConfigurationValueS
            field="prices.taxableBenefit1"
            rowField="prices.taxableBenefit1Raw"
            additionalCss={`${valueClass} audi-basket-overview-prices__desktop-hide`}
          >
            <AudiCopyS additionalClasses={` ${labelClass}`}>{taxableBenefit1Label}</AudiCopyS>
          </AudiBasketConfigurationValueS>

          <AudiBasketConfigurationValueS
            field="prices.taxableBenefit2"
            rowField="prices.taxableBenefit2Raw"
            additionalCss={`${valueClass} audi-basket-overview-prices__desktop-hide`}
          >
            <AudiCopyS additionalClasses={` ${labelClass}`}>{taxableBenefit2Label}</AudiCopyS>
          </AudiBasketConfigurationValueS>
        </div>
      )}
    </>
  );
};

export default React.forwardRef(AudiBasketOverviewPricesVariantF);
