import './audi-basket-overview-prices.css';

import * as React from 'react';
import { useContext, useEffect, useState } from 'react';

import { MarkupFromHTML } from '@oneaudi/configurator-react-utils';
import { AudiCopyS } from '@volkswagen-onehub/audi-core-ci-components';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { useConfiguratorData } from '@oneaudi/configurator-data-context';

import axios from 'axios';
import AudiBasketOverviewPricesVariantA from './variant-a/variant-a';
import AudiBasketOverviewPricesVariantB from './variant-b/variant-b';
import AudiBasketOverviewPricesVariantBRate from './variant-b-rate/variant-b-rate';
import AudiBasketOverviewPricesVariantC from './variant-c/variant-c';
import AudiBasketOverviewPricesVariantE from './variant-e/variant-e';
import AudiBasketOverviewPricesVariantF from './variant-f/variant-f';
import { AudiBasketContext } from '../audi-basket/audi-basket';
import AudiBasketPriceDetailsLink from '../audi-basket-price-details-link/audi-basket-price-details-link';
import AudiBasketPriceDetailsLayer from '../audi-basket-price-details-layer/audi-basket-price-details-layer';
import {
  createMobileViewExtendedAction,
  DisplayModeTypes,
} from '../../utils/actions/basket-actions';
import { messages } from '../../utils/i18n/messages';
import { doClickTracking, getTrackingObject } from '../../utils/helpers/tracking-helper';
import {
  MarketVariantProps,
  WltpStatus,
} from '../audi-basket-price-details/audi-basket-price-details';
import { checkHeadlessUrl } from '../../utils/helpers/check-headless-url';

const AudiBasketOverviewPrices: React.ForwardRefRenderFunction<HTMLDivElement> = (props, ref) => {
  const { basketDispatch, basketState } = useContext(AudiBasketContext);
  const [processedSpecificDeliveryUrl, setProcessedSpecificDeliveryUrl] = useState<string>();
  const {
    config: { MarketVariant, DisplayFootnoteOnSecondLine, DefaultDeliveryUrl, SpecificDeliveryUrl },
    extendedMobileView,
    displayMode,
    detailsLayerOpen,
    trackingService,
    marketContextService,
  } = basketState;
  const priceDate: string = useConfiguratorData<string>('priceDate');
  const country = marketContextService?.getContextItem('nemo.locale.country') || '';
  const language = marketContextService?.getContextItem('nemo.locale.language') || '';
  const carline = useConfiguratorData<string>('carline');
  const priceDetailsLabel = useI18n(messages.priceDetails);

  const displayPrices = MarketVariant !== 'G';

  const classPrefix = 'audi-basket-overview-prices';
  let cssClass = `${classPrefix} ${
    !extendedMobileView ? 'audi-basket-overview-prices--collapsed' : ''
  }`;
  cssClass += `${
    extendedMobileView && !displayPrices ? 'audi-basket-overview-prices--no-prices' : ''
  }`;
  const valueClass = `${classPrefix}__price-value`;

  const generalPriceInformationDisclaimer = useI18n(messages.generalPriceInformationDisclaimer);

  useEffect(() => {
    if (SpecificDeliveryUrl && carline) {
      const specificHeadlessUrl = checkHeadlessUrl(SpecificDeliveryUrl, country, language, carline);
      axios
        .get(specificHeadlessUrl)
        .then((response) => {
          if (
            response.status >= 200 &&
            response.status <= 299 &&
            response.request?.responseURL?.includes(specificHeadlessUrl)
          ) {
            setProcessedSpecificDeliveryUrl(specificHeadlessUrl);
          }
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.log(`Carline delivery costs error: ${error.message}. Using default content.`);
        });
    }
  }, [SpecificDeliveryUrl, carline]);

  const deliveryUrl =
    processedSpecificDeliveryUrl ||
    (DefaultDeliveryUrl
      ? checkHeadlessUrl(DefaultDeliveryUrl, country, language, carline)
      : undefined);
  const wltpStatusData = useConfiguratorData<WltpStatus>('wltp-status');
  const isBEV = wltpStatusData?.engineType === 'PEV';
  const showDeliveryCostsButton = Boolean(
    marketContextService?.hasEnvScope('PRICE_DETAILS_DELIVERY_COSTS') && deliveryUrl && isBEV,
  );

  const CurrentMarketVariant = React.useCallback(
    (variantProps: MarketVariantProps): JSX.Element => {
      switch (MarketVariant) {
        case 'B':
          return <AudiBasketOverviewPricesVariantB {...variantProps} />;
        case 'B_RATE':
          return <AudiBasketOverviewPricesVariantBRate {...variantProps} />;
        case 'C':
          return (
            <AudiBasketOverviewPricesVariantC
              displayFootnoteOnSecondLine={DisplayFootnoteOnSecondLine}
              {...variantProps}
            />
          );
        case 'E':
          return <AudiBasketOverviewPricesVariantE {...variantProps} />;
        case 'F':
          return <AudiBasketOverviewPricesVariantF {...variantProps} />;
        case 'A1':
          return (
            <AudiBasketOverviewPricesVariantA
              hideFootnoteOnTaxDetailsLine
              showVatTaxExtraForBev
              {...variantProps}
            />
          );
        default:
          return <AudiBasketOverviewPricesVariantA {...variantProps} />;
      }
    },
    [MarketVariant, DisplayFootnoteOnSecondLine],
  );

  const handleClickTracking = (): void => {
    if (trackingService) {
      const eventName = extendedMobileView ? 'fold basket' : 'unfold basket';
      const trackingObject = getTrackingObject({ label: '', eventName });
      doClickTracking(trackingObject, trackingService);
    }
  };

  return (
    <div className={cssClass} ref={ref}>
      {displayPrices ? (
        <div className="audi-basket-overview-prices__wrapper">
          <CurrentMarketVariant
            showDeliveryCostsButton={showDeliveryCostsButton}
            layerUrl={deliveryUrl}
          />

          {priceDate && (
            <div
              className={`${valueClass} audi-basket__financial-disclaimer audi-basket-overview-prices__desktop-hide`}
            >
              <AudiCopyS>
                <MarkupFromHTML
                  text={priceDate}
                  additionalCss="audi-basket-configuration-value__label"
                />
              </AudiCopyS>
            </div>
          )}

          {generalPriceInformationDisclaimer && (
            <AudiCopyS
              additionalClasses={`${valueClass} ${classPrefix}__general-price-information-disclaimer audi-basket-overview-prices__desktop-hide`}
            >
              <MarkupFromHTML text={generalPriceInformationDisclaimer} />
            </AudiCopyS>
          )}
        </div>
      ) : (
        <span className="audi-basket-overview-prices__no-prices-placeholder">&nbsp;</span>
      )}
      {displayMode === DisplayModeTypes.Desktop || displayMode === DisplayModeTypes.Tablet ? (
        <>
          {displayPrices && <AudiBasketPriceDetailsLink />}
          {detailsLayerOpen && <AudiBasketPriceDetailsLayer deliveryCostsLayerUrl={deliveryUrl} />}
        </>
      ) : (
        <button
          type="button"
          className="audi-basket__price-details-extend-button"
          aria-label={priceDetailsLabel}
          onClick={(): void => {
            basketDispatch(createMobileViewExtendedAction(!extendedMobileView));
            handleClickTracking();
          }}
        >
          <svg className="audi-basket__icon-arrow-up" id="audi-icon-arrow-up" viewBox="0 0 48 48">
            <path d="M33.147 28.854L24.5 20.207l-8.646 8.647-.708-.707 9.354-9.354 9.353 9.354" />
          </svg>
        </button>
      )}
    </div>
  );
};

export default React.forwardRef(AudiBasketOverviewPrices);
