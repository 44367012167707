import { AudiAudicodeServiceV1 } from '@volkswagen-onehub/audi-audicode-service';
import { AudiMarketContextServiceV1 } from '@volkswagen-onehub/audi-market-context-service';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@volkswagen-onehub/audi-footnote-reference-service';
import { ITrackingServiceV1 } from '@volkswagen-onehub/audi-tracking-service';

import { LayerManagerV25 } from '@volkswagen-onehub/layer-manager';
import { NumberFormatterServiceV1 } from '@oneaudi/number-formatter-service/dist/v1';
import { BasketState } from '../reducers/basket-reducer';
import { BasketConfig } from '../../components/audi-basket/audi-basket';

export enum BasketActionTypes {
  SetDisplayMode = 'SET_DISPLAY_MODE',
  SetMobileViewExtended = 'EXTEND_MOBILE_VIEW',
  SetDetailsLayerOpen = 'OPEN_DETAILS_LAYER',
  SetConfig = 'SET_CONFIG',
}

export enum DisplayModeTypes {
  Desktop = 'DESKTOP',
  Tablet = 'TABLET',
  Mobile = 'MOBILE_EXTENDED',
}

interface SetMobileViewExtendedAction {
  type: BasketActionTypes;
  payload: BasketState;
}

interface SetDetailsLayerOpenAction {
  type: BasketActionTypes;
  payload: BasketState;
}

interface SetDisplayModeAction {
  type: BasketActionTypes;
  payload: BasketState;
}

interface InitStateAction {
  type: BasketActionTypes;
  payload: BasketState;
}

export type IBasketAction =
  | SetMobileViewExtendedAction
  | SetDetailsLayerOpenAction
  | SetDisplayModeAction
  | InitStateAction;

export const createMobileViewExtendedAction: (
  isExtended: boolean,
) => SetMobileViewExtendedAction = (isExtended) => {
  return {
    payload: {
      extendedMobileView: isExtended,
    },
    type: BasketActionTypes.SetMobileViewExtended,
  };
};

export const createDetailsLayerOpenAction: (isLayerOpen: boolean) => SetDetailsLayerOpenAction = (
  isLayerOpen,
) => {
  return {
    payload: {
      detailsLayerOpen: isLayerOpen,
    },
    type: BasketActionTypes.SetDetailsLayerOpen,
  };
};

export const createSetDisplayModeAction: (displayMode: string) => SetDisplayModeAction = (
  displayMode,
) => {
  return {
    payload: {
      displayMode,
    },
    type: BasketActionTypes.SetDisplayMode,
  };
};

export const createInitStateAction: (
  config: BasketConfig,
  marketContextService: AudiMarketContextServiceV1,
  audiCodeService: AudiAudicodeServiceV1,
  footnoteReferenceServiceScopeManager: AudiFootnoteRefernceServiceScopeManagerInterfaceV3,
  trackingService: ITrackingServiceV1,
  layerManager: LayerManagerV25,
  localeService: LocaleServiceV1,
  numberFormatterService: NumberFormatterServiceV1,
  // eslint-disable-next-line max-params, max-len
) => InitStateAction = (
  config,
  marketContextService,
  audiCodeService,
  footnoteReferenceServiceScopeManager,
  trackingService,
  layerManager,
  localeService,
  numberFormatterService,
  // eslint-disable-next-line max-params
) => {
  return {
    payload: {
      audiCodeService,
      config,
      marketContextService,
      footnoteReferenceServiceScopeManager,
      trackingService,
      layerManager,
      localeService,
      numberFormatterService,
    },
    type: BasketActionTypes.SetConfig,
  };
};
