export function checkHeadlessUrl(
  url: string,
  country: string,
  language: string,
  carline: string,
): string {
  const formattedUrl = url
    .replace('$country', country.toLowerCase())
    .replace('$language', language)
    .replace('[web/brand]', country === 'DE' ? 'brand' : 'web')
    .replace('$carline', carline);
  const headlessLink = formattedUrl.includes('.headless.');
  const hasHtmlSuffix = formattedUrl.endsWith('.html');
  return hasHtmlSuffix && !headlessLink
    ? formattedUrl.replace('.html', '.headless.html')
    : formattedUrl;
}
