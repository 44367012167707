import './audi-basket-cta.css';

import * as React from 'react';
import { useEffect, useState, useCallback, useContext } from 'react';

import { LinkContainer, LinkModel } from '@oneaudi/configurator-react-utils';
import { useConfiguratorData } from '@oneaudi/configurator-data-context';

import { DisplayModeTypes } from '../../utils/actions/basket-actions';
import { isCurrentLocation } from '../../utils/helpers/is-current-location';
import { AudiBasketContext } from '../audi-basket/audi-basket';
import { doClickTracking, getTrackingObject } from '../../utils/helpers/tracking-helper';

const AudiBasketCta: React.FC = () => {
  const { basketState } = useContext(AudiBasketContext);
  const {
    config: { Handrail: handrail, CTA: cta, SummaryCTA: summaryCta, SummaryPageUrl },
    audiCodeService,
    marketContextService,
    trackingService,
    displayMode,
    extendedMobileView,
  } = basketState;
  const [currentCta, setCurrentCta] = useState<LinkModel>(null);
  const carlineId = useConfiguratorData<string>('carline');

  const updateCurrentCta = useCallback((): void => {
    const isMobileDevice = displayMode === DisplayModeTypes.Mobile;
    const isLastStepBeforeSummaryPage = isCurrentLocation(handrail[handrail.length - 2].URL);
    const isSummaryPage = isCurrentLocation(SummaryPageUrl);
    let showCta = false;

    if (cta && !isCurrentLocation(cta.LinkUrl)) {
      // Show on mobile if we are in the last handrail step or the mobileview is extended
      if (isMobileDevice && (isLastStepBeforeSummaryPage || isSummaryPage || extendedMobileView)) {
        showCta = true;
        // Show on desktop
      } else if (!isMobileDevice) {
        showCta = true;
      }
    }

    const showSummaryCTA = Boolean(summaryCta && isCurrentLocation(SummaryPageUrl));
    // eslint-disable-next-line no-nested-ternary
    const current = showSummaryCTA ? summaryCta : showCta ? cta : null;
    setCurrentCta(current);
  }, [SummaryPageUrl, cta, summaryCta, handrail, displayMode, extendedMobileView]);

  const handleLinkContainerClick = useCallback((): void => {
    if (trackingService && currentCta) {
      const { LinkText, LinkTrackingId, LinkUrl } = currentCta;
      const trackingObject = getTrackingObject({
        label: LinkText,
        eventName: 'primary button',
        linkTrackingId: LinkTrackingId,
      });
      doClickTracking(trackingObject, trackingService, LinkUrl);
    }
  }, [currentCta, trackingService]);

  useEffect(() => {
    document.addEventListener('PAGE_READY', updateCurrentCta);
    updateCurrentCta();

    return (): void => {
      document.removeEventListener('PAGE_READY', updateCurrentCta);
    };
  }, [updateCurrentCta]);

  return currentCta ? (
    <LinkContainer
      additionalClasses="audi-basket-button-cta audi-button audi-button--inverted"
      audiCodeService={audiCodeService}
      marketContextService={marketContextService}
      link={currentCta}
      carlineId={carlineId}
      onMouseDown={handleLinkContainerClick}
      trackingExclude
    >
      {currentCta.LinkText}
    </LinkContainer>
  ) : null;
};

export default AudiBasketCta;
