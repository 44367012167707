import * as React from 'react';
import { FocusLayerSizeV2 } from '@volkswagen-onehub/layer-manager';
import { audiLightTheme, Button, ThemeProvider } from '@audi/audi-ui-react-v2';
import { LayerContentHTML } from '@oneaudi/fa-one-layer/dist/utils';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { useContext } from 'react';
import { messages } from '../../../utils/i18n/messages';
import { AudiBasketContext } from '../../audi-basket/audi-basket';

export interface DeliveryCostsButtonProps {
  layerUrl?: string;
  additionalCss?: string;
}

export const DeliveryCostsButton: React.FC<DeliveryCostsButtonProps> = ({
  layerUrl,
  additionalCss,
}) => {
  const { basketState } = useContext(AudiBasketContext);
  const layerManager = basketState?.layerManager;

  const deliveryCosts = useI18n(messages.deliveryCosts);

  const handleOpenLayer = () => {
    layerManager?.openFocusLayer(
      () => (
        <ThemeProvider theme={audiLightTheme}>
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <LayerContentHTML enableDeepLinking url={layerUrl!} />
        </ThemeProvider>
      ),
      {},
      { userCloseable: true, size: FocusLayerSizeV2.A },
    );
  };

  return (
    <Button
      size="small"
      variant="text"
      onClick={handleOpenLayer}
      spaceStackEnd="s"
      className={additionalCss}
    >
      {deliveryCosts}
    </Button>
  );
};
