import * as React from 'react';
import { useContext } from 'react';

import { AudiCopyS } from '@volkswagen-onehub/audi-core-ci-components';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { useConfiguratorData } from '@oneaudi/configurator-data-context';

import { FootnoteReference } from '../../../utils/footnote-references/footnote-reference';
import {
  AudiBasketConfigurationValueM,
  AudiBasketConfigurationValueS,
} from '../../audi-basket-configuration-values/audi-basket-configuration-values';
import { AudiBasketContext } from '../../audi-basket/audi-basket';
import { messages } from '../../../utils/i18n/messages';
import AudiBasketFinanceLink from '../../audi-basket-finance-link/audi-basket-financing-link';
import AudiBasketRateCheckbox from '../../audi-basket-rate-checkbox/audi-basket-rate-checkbox';
import AudiBasketOverviewPricesExtraPrices from '../../audi-basket-extra-prices/audi-basket-extra-prices';
import { createMobileViewExtendedAction } from '../../../utils/actions/basket-actions';
import { MarketVariantProps } from '../../audi-basket-price-details/audi-basket-price-details';
import { DeliveryCostsButton } from '../../audi-basket-price-details/delivery-costs/delivery-costs-button';
import AudiBasketIncentivesBlock from '../../audi-basket-incentives-block/audi-basket-incentives-block';

const AudiBasketOverviewPricesVariantE: React.ForwardRefRenderFunction<
  HTMLDivElement,
  MarketVariantProps
> = ({ showDeliveryCostsButton, layerUrl }) => {
  const { basketState, basketDispatch } = useContext(AudiBasketContext);
  const {
    config: { FinanceLink },
  } = basketState;

  const classPrefix = 'audi-basket-overview-prices__';
  const labelClass = `${classPrefix}price-label`;
  const valueClass = `${classPrefix}price-value`;

  const hasRate = useConfiguratorData<number>('prices.totalRateRaw') > 0;
  const showFinanceProductLink = hasRate && FinanceLink;

  const totalRateLabel = useI18n(messages.totalRate);

  return (
    <>
      <div className="audi-basket-overview-prices__base-prices audi-basket-overview-prices__desktop-hide">
        <AudiBasketConfigurationValueS
          field="prices.model"
          rowField="prices.modelRaw"
          additionalCss={valueClass}
        >
          <AudiCopyS additionalClasses={labelClass}>{useI18n(messages.basePrice)}</AudiCopyS>
        </AudiBasketConfigurationValueS>
        <AudiBasketConfigurationValueS
          field="prices.options"
          rowField="prices.optionsRaw"
          additionalCss={valueClass}
        >
          <AudiCopyS additionalClasses={labelClass}>{useI18n(messages.equipment)}</AudiCopyS>
        </AudiBasketConfigurationValueS>
        <AudiBasketOverviewPricesExtraPrices
          LabelComponent={AudiCopyS}
          ValueComponent={AudiBasketConfigurationValueS}
          ClassBase={classPrefix}
        />
      </div>
      <AudiBasketIncentivesBlock variant="mobile" tagName={AudiCopyS} />
      <AudiBasketConfigurationValueS
        additionalCss={`${valueClass} audi-basket__price-total audi-basket__additional-prices`}
        field="prices.total"
        rowField="prices.totalRaw"
      >
        <AudiCopyS additionalClasses={labelClass}>
          {useI18n(messages.priceTotal)}
          <FootnoteReference
            refId="nemo-fn_price"
            inverted
            onClick={(): void => basketDispatch(createMobileViewExtendedAction(false))}
          />
        </AudiCopyS>
      </AudiBasketConfigurationValueS>
      {showDeliveryCostsButton && (
        <DeliveryCostsButton
          additionalCss="audi-basket-overview-prices__delivery-cost audi-basket-overview-prices__desktop-hide"
          layerUrl={layerUrl}
        />
      )}
      {hasRate && (
        <AudiBasketConfigurationValueM
          field="prices.totalRate"
          rowField="prices.totalRateRaw"
          additionalCss={`${valueClass} audi-basket__price-prominent-rate`}
          additionalProps={{ bold: true }}
        >
          <AudiCopyS additionalClasses={`${labelClass} audi-basket__price-prominent-rate__label`}>
            {totalRateLabel}
            <FootnoteReference refId="nemo-fn_financing" inverted />
          </AudiCopyS>
        </AudiBasketConfigurationValueM>
      )}
      <div className="audi-basket__finance-data audi-basket-overview-prices__desktop-hide">
        {showFinanceProductLink && (
          <AudiBasketConfigurationValueS
            additionalCss={`${valueClass} audi-basket-overview-prices__finance-product-wrapper`}
            field="financeProductName"
            rowField="financeProductName"
            footnoteRef="nemo-fn_financing"
            footnoteInverted
          >
            <AudiBasketFinanceLink
              additionalClasses="audi-basket__finance-product-link"
              variant="inverted"
            />
          </AudiBasketConfigurationValueS>
        )}
        {hasRate && (
          <AudiBasketRateCheckbox additionalClasses="audi-basket-rate-checkbox--inverted audi-basket-rate-checkbox--overview audi-basket-overview-prices__desktop-hide" />
        )}
      </div>
    </>
  );
};

export default React.forwardRef(AudiBasketOverviewPricesVariantE);
