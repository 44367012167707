import * as React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';

import { AudiBasketContext, AudiBasketProps, INITIAL_BASKET_STATE } from './audi-basket';
import { getDisplayMode } from './get-display-mode';
import { createSetDisplayModeAction, DisplayModeTypes } from '../../utils/actions/basket-actions';
import { usePortal } from '../../utils/hooks/use-portal';
import { useWindowSize } from '../../utils/hooks/use-window-size';
import AudiBasketOverViewCarinfo from '../audi-basket-overview-carinfo/audi-basket-overview-carinfo';
import AudiBasketOverviewPrices from '../audi-basket-overview-prices/audi-basket-overview-prices';
import AudiBasketOverviewActions from '../audi-basket-overview-actions/audi-basket-overview-actions';

const BASKET_BREAKPOINT = 768;
const BASKET_PADDING = 0.04;

const AudiBasketMeasure: React.FC<AudiBasketProps> = ({
  audiCodeService,
  config,
  marketContextService,
}) => {
  const { Portal } = usePortal('audi-basket--measure');

  const { basketState, basketDispatch } = useContext(AudiBasketContext);
  const {
    config: { MarketVariant },
  } = basketState;
  const windowSize = useWindowSize(0, 0);
  const measureElement = useRef<HTMLDivElement>(null);

  const carinfoRef = useRef<HTMLDivElement>(null);
  const pricesRef = useRef<HTMLDivElement>(null);
  const actionsRef = useRef<HTMLDivElement>(null);

  const observer = useRef<ResizeObserver>(null);
  const [contentResized, setContentResized] = useState<boolean>(false);

  useEffect(() => {
    if (window.ResizeObserver && !observer.current) {
      observer.current = new ResizeObserver(() => {
        setContentResized(true);
      });
      observer.current.observe(carinfoRef.current);
      observer.current.observe(pricesRef.current);
      observer.current.observe(actionsRef.current);
    }

    return (): void => {
      observer.current.disconnect();
    };
  }, []);
  useEffect(() => {
    setContentResized(false);

    if (document.body.clientWidth < BASKET_BREAKPOINT) {
      basketDispatch(createSetDisplayModeAction(DisplayModeTypes.Mobile));
      return;
    }

    if (measureElement.current) {
      setTimeout(() => {
        const maxWidth = Math.ceil(document.body.clientWidth * (1 - BASKET_PADDING));
        const contentWidth = [...measureElement.current.children].reduce((maxValue, child) => {
          const elementRight = Math.floor(child.getBoundingClientRect().right);
          return elementRight > maxValue ? elementRight : maxValue;
        }, 0);

        // Get width of the carinfo to calculate if we could display the Tablet breakpoint
        let carInfoWidth = 0;
        if (carinfoRef.current) {
          carInfoWidth = Math.floor(carinfoRef.current.getBoundingClientRect().right);
        }

        const displayMode = getDisplayMode(contentWidth, maxWidth, carInfoWidth, MarketVariant);
        basketDispatch(createSetDisplayModeAction(displayMode));
      }, 0);
    }
  }, [basketDispatch, windowSize, contentResized, MarketVariant]);

  const fixedBasketContext = useRef({
    basketDispatch: (): void => {
      // do nothing, we won't change the clone
    },
    basketState: {
      ...INITIAL_BASKET_STATE,
      audiCodeService,
      config,
      marketContextService,
    },
  });

  return (
    <AudiBasketContext.Provider value={fixedBasketContext.current}>
      <Portal>
        <div className="audi-basket audi-basket--desktop">
          <div className="audi-basket__content-wrapper" ref={measureElement}>
            <AudiBasketOverViewCarinfo ref={carinfoRef} />
            <AudiBasketOverviewPrices ref={pricesRef} />
            <AudiBasketOverviewActions ref={actionsRef} />
          </div>
        </div>
      </Portal>
    </AudiBasketContext.Provider>
  );
};

export default AudiBasketMeasure;
