import './audi-basket-handrail.css';
import * as React from 'react';
import { useEffect, useState, useContext, useMemo, useCallback } from 'react';

import { AudiLinkButtonGhost } from '@volkswagen-onehub/audi-core-ci-components';

import { isCurrentLocation } from '../../utils/helpers/is-current-location';
import { HandrailItem, AudiBasketContext } from '../audi-basket/audi-basket';
import { doClickTracking, getTrackingObject } from '../../utils/helpers/tracking-helper';

interface PathItem {
  current: HandrailItem;
  next?: HandrailItem;
}

const AudiBasketHandrail: React.FC = () => {
  const { basketState } = useContext(AudiBasketContext);
  const {
    config: {
      Handrail: handrailConfig,
      CTA: { LinkUrl: ctaLinkUrl },
    },
    trackingService,
  } = basketState;

  const [currentPageItem, setCurrentPageItem] = useState<PathItem>();

  const paths = useMemo(() => {
    return handrailConfig.map<PathItem>((handrail, index, array) => ({
      current: handrail,
      next: array[index + 1],
    }));
  }, [handrailConfig]);

  const isHandrailActive = useCallback((): boolean => {
    return currentPageItem?.next?.URL && currentPageItem.next.URL !== ctaLinkUrl;
  }, [ctaLinkUrl, currentPageItem]);

  const pageloadedHandler = useCallback((): void => {
    setCurrentPageItem(paths.find((item) => isCurrentLocation(item.current.URL)));
  }, [paths]);

  const handleClickTracking = useCallback((): void => {
    if (trackingService && currentPageItem) {
      const trackingObject = getTrackingObject({
        label: currentPageItem.next.Label,
        eventName: 'secondary button',
      });
      doClickTracking(trackingObject, trackingService, currentPageItem.next.URL);
    }
  }, [currentPageItem, trackingService]);

  useEffect(() => {
    document.addEventListener('PAGE_READY', pageloadedHandler);
    pageloadedHandler();

    return (): void => {
      document.removeEventListener('PAGE_READY', pageloadedHandler);
    };
  }, [pageloadedHandler]);

  return isHandrailActive() ? (
    <div className="audi-basket-handrail">
      <AudiLinkButtonGhost
        additionalClasses="nm-pageOpen"
        href={currentPageItem.next.URL}
        linkProps={{ onMouseDown: handleClickTracking }}
        trackingExclude
        variant="inverted"
      >
        {currentPageItem.next.Label}
      </AudiLinkButtonGhost>
    </div>
  ) : null;
};

export default AudiBasketHandrail;
