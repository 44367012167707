import './audi-basket-overview-carinfo.css';
import * as React from 'react';
import { useContext } from 'react';

import {
  AudiCopyM,
  AudiCopyS,
  AudiHeadlineOrder3,
} from '@volkswagen-onehub/audi-core-ci-components';
import { useConfiguratorData } from '@oneaudi/configurator-data-context';

import { FootnoteReference } from '../../utils/footnote-references/footnote-reference';
import { AudiBasketContext } from '../audi-basket/audi-basket';
import { createMobileViewExtendedAction } from '../../utils/actions/basket-actions';

const AudiBasketOverViewCarinfo: React.ForwardRefRenderFunction<HTMLDivElement> = (props, ref) => {
  const { basketState, basketDispatch } = useContext(AudiBasketContext);
  const {
    config: { MarketVariant },
  } = basketState;

  let cssClass = 'audi-basket-overview-carinfo';
  cssClass += MarketVariant !== 'G' ? ` ${cssClass}--border` : '';

  const carlineNameBase = useConfiguratorData<string>('carlineNameBase')?.trim();
  const carlineNameSub = useConfiguratorData<string>('carlineNameSub')?.trim();
  const engineName = useConfiguratorData<string>('description')?.trim();

  return (
    <div className={cssClass} ref={ref}>
      {carlineNameBase && (
        <AudiHeadlineOrder3 additionalClasses="audi-basket-overview-carinfo__carline">
          {carlineNameBase}
        </AudiHeadlineOrder3>
      )}
      {carlineNameSub && <AudiCopyM>{carlineNameSub}</AudiCopyM>}
      {engineName && (
        <AudiCopyS additionalClasses="audi-basket-overview-carinfo__engine">
          {engineName}
          <FootnoteReference
            refId="nemo-fn_eec-disclaimer"
            inverted
            onClick={(): void => basketDispatch(createMobileViewExtendedAction(false))}
          />
        </AudiCopyS>
      )}
    </div>
  );
};

export default React.forwardRef(AudiBasketOverViewCarinfo);
