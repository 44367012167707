import { LinkTarget } from '../../components/audi-basket/audi-basket';

interface LinkTargetParams {
  targetParam: string;
  className: string;
}

export const useLinkTarget = (target: LinkTarget): LinkTargetParams => {
  switch (target) {
    case '_blank': {
      return {
        className: '',
        targetParam: '_blank',
      };
    }
    case '_inpage': {
      return {
        className: 'nm-pageOpen',
        targetParam: '',
      };
    }
    case '_layer': {
      return {
        className: 'nm-layerLink',
        targetParam: '',
      };
    }
    case '_self': {
      return {
        className: '',
        targetParam: '_self',
      };
    }
    default: {
      return {
        className: '',
        targetParam: '',
      };
    }
  }
};
