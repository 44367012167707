import * as React from 'react';

import { ShowPricesMode } from '@oneaudi/configurator-data-service';
import { AudiCheckbox } from '@volkswagen-onehub/audi-core-ci-components';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { useHeaderData } from '@oneaudi/configurator-data-context';

import { messages } from '../../utils/i18n/messages';
import './audi-basket-rate-checkbox.css';

interface AudiBasketRateCheckboxProps {
  additionalClasses?: string;
}

const AudiBasketRateCheckbox: React.FC<AudiBasketRateCheckboxProps> = ({ additionalClasses }) => {
  const label = useI18n(messages.showPricesAsRates);
  const mode: ShowPricesMode = useHeaderData<ShowPricesMode>('mode');

  const handleModeChange = (): void => {
    const setMode = mode === 'rate' ? 'price' : 'rate';
    const event = new CustomEvent('rateSwitch', {
      detail: {
        mode: setMode,
      },
    });
    document?.dispatchEvent(event);
  };

  return (
    <div className={`audi-basket-rate-checkbox ${additionalClasses || ''}`}>
      <AudiCheckbox
        additionalClasses={additionalClasses}
        size="audi-copy-s"
        checked={mode === 'rate'}
        onChange={handleModeChange}
      >
        {label}
      </AudiCheckbox>
    </div>
  );
};

export default AudiBasketRateCheckbox;
