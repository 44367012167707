// eslint-disable-next-line import/no-unresolved
import { EnumerableFootnote } from '@volkswagen-onehub/audi-footnote-reference-service/dist/store/v2/footnote-reference-store';

export enum FootnoteReferencesActionTypes {
  AddFootnoteReference,
  RemoveFootnoteReference,
  SetFootnoteReferencesNumbers,
}

interface AddFootnoteReferenceAction {
  type: FootnoteReferencesActionTypes.AddFootnoteReference;
  payload: string;
}

interface RemoveFootnoteReferenceAction {
  type: FootnoteReferencesActionTypes.RemoveFootnoteReference;
  payload: string;
}

interface SetFootnoteReferencesNumbersAction {
  type: FootnoteReferencesActionTypes.SetFootnoteReferencesNumbers;
  payload: EnumerableFootnote[];
}

export type FootnoteReferencesAction =
  | AddFootnoteReferenceAction
  | RemoveFootnoteReferenceAction
  | SetFootnoteReferencesNumbersAction;

export const createAddFootnoteReferenceAction: (refId: string) => AddFootnoteReferenceAction = (
  refId,
) => {
  return {
    payload: refId,
    type: FootnoteReferencesActionTypes.AddFootnoteReference,
  };
};

export const createRemoveFootnoteReferenceAction: (
  refId: string,
) => RemoveFootnoteReferenceAction = (refId) => {
  return {
    payload: refId,
    type: FootnoteReferencesActionTypes.RemoveFootnoteReference,
  };
};

export const createSetFootnoteReferencesNumbersAction: (
  numberedFootnotes: EnumerableFootnote[],
) => SetFootnoteReferencesNumbersAction = (numberedFootnotes) => {
  return {
    payload: numberedFootnotes,
    type: FootnoteReferencesActionTypes.SetFootnoteReferencesNumbers,
  };
};
