import { ITrackingServiceV1 } from '@volkswagen-onehub/audi-tracking-service';

import pkg from '../../../../package.json';

export const TRACKING_COMPONENT_NAME = 'basket';

interface CTATrackingObject {
  ctaEventAction: string;
  ctaValue: string;
  ctaTargetURL: string;
}

interface TrackingObject {
  label: string;
  eventName: string;
  linkTrackingId?: string;
}

export const getLinkType = (
  originUrl: string,
  targetUrl: string,
): 'exit_link' | 'internal_link' => {
  try {
    if (new URL(originUrl).host !== new URL(targetUrl).host) {
      return 'exit_link';
    }
  } catch {
    // do nothing
  }

  return 'internal_link';
};

export const getCTATrackingObject = (
  linkTrackingId: string,
  targetURL: string,
  currentURL: string,
): CTATrackingObject => {
  let ctaEventAction = '';
  let ctaValue = '';
  let ctaTargetURL = targetURL;
  switch (linkTrackingId) {
    case 'download pdf':
      ctaEventAction = 'download';
      ctaValue = 'PDF';
      break;
    case 'image download':
      ctaEventAction = 'download';
      break;
    case 'poa link':
      ctaEventAction = 'content';
      ctaTargetURL = '';
      break;
    default:
      ctaEventAction = getLinkType(currentURL, ctaTargetURL);
  }
  return { ctaEventAction, ctaValue, ctaTargetURL };
};

export const getTrackingObject = (trackingObject: TrackingObject): TrackingObject => {
  return { ...trackingObject };
};

export const doClickTracking = (
  trackingObject: TrackingObject,
  trackingService: ITrackingServiceV1,
  url?: string,
): void => {
  const { eventName, label, linkTrackingId } = trackingObject;
  const currentURL = typeof window !== 'undefined' ? window.location.href : '';
  let elementName = 'button';
  let eventAction = '';
  let value = '';
  let targetURL = url || '';

  switch (eventName) {
    case 'primary button':
      // eslint-disable-next-line no-case-declarations
      const { ctaEventAction, ctaValue, ctaTargetURL } = getCTATrackingObject(
        linkTrackingId || '',
        url || '',
        currentURL,
      );
      eventAction = ctaEventAction;
      value = ctaValue;
      targetURL = ctaTargetURL;
      break;
    case 'price details':
      eventAction = 'content';
      elementName = 'text link';
      break;
    case 'fold basket':
    case 'unfold basket':
      eventAction = 'content';
      elementName = 'icon';
      break;
    default:
      eventAction = getLinkType(currentURL, targetURL);
      break;
  }

  trackingService.track({
    event: {
      eventInfo: {
        eventAction,
        eventName: `${TRACKING_COMPONENT_NAME} - click ${
          eventName === 'fold basket' || eventName === 'unfold basket' ? 'to' : 'on'
        } ${linkTrackingId ?? eventName}`,
      },
      attributes: {
        value,
        targetURL,
        currentURL,
        componentName: TRACKING_COMPONENT_NAME,
        label,
        elementName,
        clickID: '',
        pos: '',
      },
    },
  });
};

export const doFeatureAppReadyTracking = (trackingService: ITrackingServiceV1): void => {
  const currentURL = typeof window !== 'undefined' ? window.location.href : '';

  trackingService.featureAppName = 'audi-feature-app-basket';
  trackingService.track({
    event: {
      eventInfo: {
        eventAction: 'feature_app_ready',
        eventName: 'feature app ready',
      },
      attributes: {
        currentURL,
        componentName: TRACKING_COMPONENT_NAME,
        label: '',
        targetURL: '',
        clickID: '',
        elementName: '',
        value: '',
        pos: '',
      },
    },
    datalayerAdditions: {
      componentInfo: {
        version: pkg.version,
        implementer: 1,
      },
    },
  });
};
