import * as React from 'react';

import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { useConfiguratorData } from '@oneaudi/configurator-data-context';
import {
  AudiHeadlineOrder2,
  AudiHeadlineOrder3,
  AudiCopyM,
} from '@volkswagen-onehub/audi-core-ci-components';

import { FootnoteReference } from '../../../utils/footnote-references/footnote-reference';
import {
  AudiBasketConfigurationValueH3,
  AudiBasketConfigurationValueM,
} from '../../audi-basket-configuration-values/audi-basket-configuration-values';
import { messages } from '../../../utils/i18n/messages';
import AudiBasketOverviewPricesExtraPrices from '../../audi-basket-extra-prices/audi-basket-extra-prices';
import { MarketVariantProps } from '../audi-basket-price-details';
import { DeliveryCostsButton } from '../delivery-costs/delivery-costs-button';
import AudiBasketIncentivesBlock from '../../audi-basket-incentives-block/audi-basket-incentives-block';

const AudiBasketPriceDetailsVariantF: React.FC<MarketVariantProps> = ({
  showDeliveryCostsButton,
  layerUrl,
}) => {
  const classPrefix = 'audi-basket-price-details__';
  const labelClass = `${classPrefix}price-label`;
  const valueClass = `${classPrefix}price-value`;

  const carlineNamePrefix = useI18n(messages.yourCarline);
  const carlineName = useConfiguratorData<string>('carlineName')?.trim();
  const engineName = useConfiguratorData<string>('description')?.trim();

  const taxableBenefit1 = useConfiguratorData<string>('prices.taxableBenefit1');
  const taxableBenefit2 = useConfiguratorData<string>('prices.taxableBenefit2');
  const showTaxableBenefits =
    (taxableBenefit1 && taxableBenefit1 !== '') || (taxableBenefit2 && taxableBenefit2 !== '');
  const companyCarLabel = useI18n(messages.companyCar);
  const taxableBenefit1Label = useI18n(messages.taxableBenefit1);
  const taxableBenefit2Label = useI18n(messages.taxableBenefit2);

  return (
    <>
      {carlineName && (
        <AudiHeadlineOrder2 additionalClasses={`${classPrefix}carline`}>
          {carlineNamePrefix} {carlineName}
        </AudiHeadlineOrder2>
      )}
      {engineName && (
        <AudiHeadlineOrder3 additionalClasses={`${classPrefix}subline`}>
          {engineName}
          <FootnoteReference refId="nemo-fn_eec-disclaimer" />
        </AudiHeadlineOrder3>
      )}

      <AudiBasketOverviewPricesExtraPrices
        LabelComponent={AudiCopyM}
        ValueComponent={AudiBasketConfigurationValueM}
        ClassBase={classPrefix}
      />
      <AudiBasketIncentivesBlock variant="desktop" tagName={AudiCopyM} />

      <AudiBasketConfigurationValueM
        field="prices.nettoOptions"
        rowField="prices.nettoOptionsRaw"
        additionalCss={valueClass}
      >
        <AudiCopyM additionalClasses={labelClass}>{useI18n(messages.optionsPriceNetto)}</AudiCopyM>
      </AudiBasketConfigurationValueM>

      <div className={`${classPrefix}divider`} />

      <AudiBasketConfigurationValueH3
        field="prices.total"
        rowField="prices.totalRaw"
        additionalCss={valueClass}
        additionalProps={{ bold: true }}
      >
        <AudiCopyM additionalClasses={labelClass}>
          {useI18n(messages.priceTotal)}
          <FootnoteReference refId="nemo-fn_price" />
        </AudiCopyM>
      </AudiBasketConfigurationValueH3>
      {showDeliveryCostsButton && <DeliveryCostsButton layerUrl={layerUrl} />}

      {showTaxableBenefits && (
        <>
          <AudiCopyM additionalClasses={`${labelClass} ${classPrefix}company-car-information`}>
            {companyCarLabel}
          </AudiCopyM>
          <AudiBasketConfigurationValueM
            field="prices.taxableBenefit1"
            rowField="prices.taxableBenefit1Raw"
            additionalCss={valueClass}
          >
            <AudiCopyM additionalClasses={labelClass}>{taxableBenefit1Label}</AudiCopyM>
          </AudiBasketConfigurationValueM>
          <AudiBasketConfigurationValueM
            field="prices.taxableBenefit2"
            rowField="prices.taxableBenefit2Raw"
            additionalCss={valueClass}
          >
            <AudiCopyM additionalClasses={labelClass}>{taxableBenefit2Label}</AudiCopyM>
          </AudiBasketConfigurationValueM>
        </>
      )}
    </>
  );
};

export default AudiBasketPriceDetailsVariantF;
