import * as React from 'react';
import { useContext } from 'react';

import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { useConfiguratorData } from '@oneaudi/configurator-data-context';
import {
  AudiHeadlineOrder2,
  AudiHeadlineOrder3,
  AudiCopyM,
} from '@volkswagen-onehub/audi-core-ci-components';

import { FootnoteReference } from '../../../utils/footnote-references/footnote-reference';
import {
  AudiBasketConfigurationValueS,
  AudiBasketConfigurationValueH3,
  AudiBasketConfigurationValueM,
} from '../../audi-basket-configuration-values/audi-basket-configuration-values';
import { messages } from '../../../utils/i18n/messages';
import AudiBasketFinanceLink from '../../audi-basket-finance-link/audi-basket-financing-link';
import { AudiBasketContext } from '../../audi-basket/audi-basket';
import AudiBasketRateCheckbox from '../../audi-basket-rate-checkbox/audi-basket-rate-checkbox';
import AudiBasketOverviewPricesExtraPrices from '../../audi-basket-extra-prices/audi-basket-extra-prices';
import { DeliveryCostsButton } from '../delivery-costs/delivery-costs-button';
import { MarketVariantProps, WltpStatus } from '../audi-basket-price-details';
import AudiBasketIncentivesBlock from '../../audi-basket-incentives-block/audi-basket-incentives-block';

export type AudiBasketPriceDetailsVariantAProps = {
  hideFootnoteOnTaxDetailsLine?: boolean;
  showVatTaxExtraForBev?: boolean;
} & MarketVariantProps;

const AudiBasketPriceDetailsVariantA: React.FC<AudiBasketPriceDetailsVariantAProps> = ({
  hideFootnoteOnTaxDetailsLine,
  showVatTaxExtraForBev,
  showDeliveryCostsButton,
  layerUrl,
}) => {
  const { basketState } = useContext(AudiBasketContext);
  const {
    config: { FinanceLink },
    marketContextService,
  } = basketState;

  const classPrefix = 'audi-basket-price-details__';
  const labelClass = `${classPrefix}price-label`;
  const valueClass = `${classPrefix}price-value`;

  const totalPriceField =
    useConfiguratorData<number>('prices.chargesRaw') > 0
      ? 'prices.totalWithCharges'
      : 'prices.total';

  const checkValueTotalPriceField =
    useConfiguratorData<number>('prices.chargesRaw') > 0
      ? 'prices.totalWithChargesRaw'
      : 'prices.totalRaw';

  const hasRate = useConfiguratorData<number>('prices.totalRateRaw') > 0;
  const showFinanceProductLink = hasRate && FinanceLink;

  const totalRateLabel = useI18n(messages.totalRate);
  const carlineNamePrefix = useI18n(messages.yourCarline);
  const totalPriceLabel = useI18n(messages.priceTotal);
  const totalPriceLabelBEV = useI18n(messages.priceTotalBEV);
  const carlineName = useConfiguratorData<string>('carlineName')?.trim();
  const engineName = useConfiguratorData<string>('description')?.trim();

  const wltpStatusData = useConfiguratorData<WltpStatus>('wltp-status');
  const isBEV = wltpStatusData?.engineType === 'PEV';
  const showBEVLabel = isBEV && Boolean(marketContextService?.hasEnvScope('BASKET_BEV_LABELS'));

  const totalPriceDisclaimerID = showBEVLabel ? 'nemo-fn_price_BEV' : 'nemo-fn_price';

  return (
    <>
      {carlineName && (
        <AudiHeadlineOrder2 additionalClasses={`${classPrefix}carline`}>
          {carlineNamePrefix} {carlineName}
        </AudiHeadlineOrder2>
      )}
      {engineName && (
        <AudiHeadlineOrder3 additionalClasses={`${classPrefix}subline`}>
          {engineName}
          <FootnoteReference refId="nemo-fn_eec-disclaimer" />
        </AudiHeadlineOrder3>
      )}

      <AudiBasketConfigurationValueM
        field="prices.model"
        rowField="prices.modelRaw"
        additionalCss={valueClass}
      >
        <AudiCopyM additionalClasses={labelClass}>{useI18n(messages.basePrice)}</AudiCopyM>
      </AudiBasketConfigurationValueM>
      <AudiBasketConfigurationValueM
        field="prices.options"
        rowField="prices.optionsRaw"
        additionalCss={valueClass}
      >
        <AudiCopyM additionalClasses={labelClass}>{useI18n(messages.equipment)}</AudiCopyM>
      </AudiBasketConfigurationValueM>

      <AudiBasketOverviewPricesExtraPrices
        LabelComponent={AudiCopyM}
        ValueComponent={AudiBasketConfigurationValueM}
        ClassBase={classPrefix}
        hideFootnoteOnTaxDetailsLine={hideFootnoteOnTaxDetailsLine}
        showVatTaxExtraForBev={showVatTaxExtraForBev}
      />
      <AudiBasketIncentivesBlock variant="desktop" tagName={AudiCopyM} />

      <div className={`${classPrefix}divider`} />

      <AudiBasketConfigurationValueH3
        field={totalPriceField}
        rowField={checkValueTotalPriceField}
        additionalCss={valueClass}
      >
        <AudiCopyM additionalClasses={labelClass}>
          {showBEVLabel ? totalPriceLabelBEV : totalPriceLabel}
          <FootnoteReference refId={totalPriceDisclaimerID} />
        </AudiCopyM>
      </AudiBasketConfigurationValueH3>
      {showDeliveryCostsButton && <DeliveryCostsButton layerUrl={layerUrl} />}
      {hasRate && (
        <AudiBasketConfigurationValueM
          field="prices.totalRate"
          rowField="prices.totalRateRaw"
          additionalCss={`${valueClass} ${valueClass}--rate`}
        >
          <AudiCopyM additionalClasses={labelClass}>
            {totalRateLabel}
            <FootnoteReference refId="nemo-fn_financing" inverted />
          </AudiCopyM>
        </AudiBasketConfigurationValueM>
      )}
      {showFinanceProductLink && (
        <AudiBasketConfigurationValueS
          field="financeProductName"
          rowField="financeProductName"
          additionalCss={`${valueClass} ${valueClass}--financing-product`}
          footnoteRef="nemo-fn_financing"
        >
          <AudiBasketFinanceLink additionalClasses={`${labelClass} ${labelClass}--financing`} />
        </AudiBasketConfigurationValueS>
      )}
      {hasRate && <AudiBasketRateCheckbox />}
    </>
  );
};

export default AudiBasketPriceDetailsVariantA;
