import * as React from 'react';
import { useContext } from 'react';

import './audi-basket-price-details.css';
import { MarkupFromHTML } from '@oneaudi/configurator-react-utils';
import { AudiCopyS } from '@volkswagen-onehub/audi-core-ci-components';
import { useI18n } from '@volkswagen-onehub/audi-i18n-context';
import { useConfiguratorData } from '@oneaudi/configurator-data-context';

import { AudiBasketContext } from '../audi-basket/audi-basket';
import AudiBasketPriceDetailsVariantA from './variant-a/variant-a';
import AudiBasketPriceDetailsVariantB from './variant-b/variant-b';
import AudiBasketPriceDetailsVariantBRate from './variant-b-rate/variant-b-rate';
import AudiBasketPriceDetailsVariantC from './variant-c/variant-c';
import AudiBasketPriceDetailsVariantF from './variant-f/variant-f';
import AudiBasketPriceDetailsVariantE from './variant-e/variant-e';
import AudiBasketPriceDetailsLayerFootnotes from '../audi-basket-price-details-layer-footnotes/audi-basket-price-details-layer-footnotes';
import { messages } from '../../utils/i18n/messages';

export interface MarketVariantProps {
  showDeliveryCostsButton: boolean;
  layerUrl?: string;
}

export interface WltpStatus {
  engineType: string;
}

interface AudiBasketPriceDetailsProps {
  deliveryCostsLayerUrl: string;
}

const AudiBasketPriceDetails: React.FC<AudiBasketPriceDetailsProps> = ({
  deliveryCostsLayerUrl,
}) => {
  const { basketState } = useContext(AudiBasketContext);
  const {
    config: { MarketVariant, DisplayFootnoteOnSecondLine },
    marketContextService,
  } = basketState;

  const classPrefix = 'audi-basket-price-details__';
  const valueClass = `${classPrefix}price-value`;

  const generalPriceInformationDisclaimer = useI18n(messages.generalPriceInformationDisclaimer);
  const priceDate: string = useConfiguratorData<string>('priceDate');

  const wltpStatusData = useConfiguratorData<WltpStatus>('wltp-status');
  const isBEV = wltpStatusData?.engineType === 'PEV';
  const showDeliveryCostsButton = Boolean(
    marketContextService?.hasEnvScope('PRICE_DETAILS_DELIVERY_COSTS') &&
      deliveryCostsLayerUrl &&
      isBEV,
  );

  const CurrentMarketVariant = React.useCallback(
    (props: MarketVariantProps): JSX.Element => {
      switch (MarketVariant) {
        case 'B':
          return <AudiBasketPriceDetailsVariantB {...props} />;
        case 'B_RATE':
          return <AudiBasketPriceDetailsVariantBRate {...props} />;
        case 'C':
          return (
            <AudiBasketPriceDetailsVariantC
              displayFootnoteOnSecondLine={DisplayFootnoteOnSecondLine}
              {...props}
            />
          );
        case 'F':
          return <AudiBasketPriceDetailsVariantF {...props} />;
        case 'E':
          return <AudiBasketPriceDetailsVariantE {...props} />;
        case 'A1':
          return (
            <AudiBasketPriceDetailsVariantA
              hideFootnoteOnTaxDetailsLine
              showVatTaxExtraForBev
              {...props}
            />
          );
        default:
          return <AudiBasketPriceDetailsVariantA {...props} />;
      }
    },
    [MarketVariant, DisplayFootnoteOnSecondLine],
  );

  return (
    <div className="audi-basket_price-details">
      <CurrentMarketVariant
        showDeliveryCostsButton={showDeliveryCostsButton}
        layerUrl={deliveryCostsLayerUrl}
      />

      {priceDate && (
        <div className={`${classPrefix}financial-disclaimer`}>
          <AudiCopyS>
            <MarkupFromHTML
              text={priceDate}
              additionalCss="audi-basket-configuration-value__label"
            />
          </AudiCopyS>
        </div>
      )}

      {generalPriceInformationDisclaimer && (
        <AudiCopyS
          additionalClasses={`${valueClass} ${classPrefix}general-price-information-disclaimer`}
        >
          <MarkupFromHTML text={generalPriceInformationDisclaimer} />
        </AudiCopyS>
      )}
      <AudiBasketPriceDetailsLayerFootnotes />
    </div>
  );
};

export default AudiBasketPriceDetails;
