export function getNumFromPixelString(str: string): number {
  if (str.indexOf('px') === -1) {
    throw new Error('string does not contain px!');
  }
  const splitArray = str.split('px');

  return Number(splitArray[0]);
}

export function getHeightWithMarginAndPadding(elem: HTMLDivElement, clientHeight: number): number {
  const element = typeof window !== 'undefined' ? window.getComputedStyle(elem) : null;

  if (!element) {
    throw new Error('window context does not exist and element is null!');
  }

  const marginTop = getNumFromPixelString(element.getPropertyValue('margin-top'));
  const marginBottom = getNumFromPixelString(element.getPropertyValue('margin-bottom'));
  const paddingTop = getNumFromPixelString(element.getPropertyValue('padding-top'));
  const paddingBottom = getNumFromPixelString(element.getPropertyValue('padding-bottom'));

  const elementHeight = clientHeight + marginTop + paddingTop + marginBottom + paddingBottom;
  return elementHeight;
}
