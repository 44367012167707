import { useCallback, useEffect, useState } from 'react';

type WindowSize = {
  width: number;
  height: number;
};

export const useWindowSize = (initialWidth = Infinity, initialHeight = Infinity): WindowSize => {
  const isClient = useCallback(() => typeof window === 'object', []);

  const [state, setState] = useState<WindowSize>({
    width: isClient ? window.innerWidth : initialWidth,
    height: isClient ? window.innerHeight : initialHeight,
  });

  useEffect((): (() => void) | void => {
    if (isClient) {
      const handler = (): void => {
        setState({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };

      window.addEventListener('resize', handler);

      return (): void => {
        window.removeEventListener('resize', handler);
      };
    }
  }, [isClient, setState]);

  return state;
};
