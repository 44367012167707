import { useEffect, useRef, useState } from 'react';

export const useIntersection = (
  el: HTMLElement,
  options: IntersectionObserverInit,
): IntersectionObserverEntry | null => {
  const [intersectionObserverEntry, setIntersectionObserverEntry] =
    useState<IntersectionObserverEntry | null>(null);
  const observer = useRef<IntersectionObserver | null>(null);
  useEffect(() => {
    return (): void => {
      setIntersectionObserverEntry(null);
      if (observer.current) {
        observer.current.disconnect();
      }
    };
  }, []);
  useEffect(() => {
    if (el && typeof IntersectionObserver === 'function') {
      const handler = (entries: IntersectionObserverEntry[]): void => {
        setIntersectionObserverEntry(entries[0]);
      };

      if (!observer.current) {
        observer.current = new IntersectionObserver(handler, options);
        observer.current.observe(el);
      }
    }
  }, [el, options]);

  return intersectionObserverEntry;
};
