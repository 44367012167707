import './audi-basket-configuration-values.css';

import * as React from 'react';
import { useContext } from 'react';

import {
  AudiCopyM,
  AudiCopyProps,
  AudiCopyS,
  AudiHeadlineOrder3,
  AudiHeadlineProps,
} from '@volkswagen-onehub/audi-core-ci-components';
import { useConfiguratorData } from '@oneaudi/configurator-data-context';

import { FootnoteReference } from '../../utils/footnote-references/footnote-reference';
import { createMobileViewExtendedAction } from '../../utils/actions/basket-actions';
import { AudiBasketContext } from '../audi-basket/audi-basket';

interface PriceProps {
  field: string;
  rowField?: string;
  additionalProps?: ValueProps;
  additionalCss?: string;
  footnoteRef?: string;
  footnoteInverted?: boolean;
}

export const AudiBasketConfigurationValueM: React.FC<PriceProps> = (props) => {
  return <AudiBasketConfigurationValueBase TagName={AudiCopyM} {...props} />;
};

export const AudiBasketConfigurationValueS: React.FC<PriceProps> = (props) => {
  return <AudiBasketConfigurationValueBase TagName={AudiCopyS} {...props} />;
};

export const AudiBasketConfigurationValueH3: React.FC<PriceProps> = (props) => {
  return <AudiBasketConfigurationValueBase TagName={AudiHeadlineOrder3} {...props} />;
};

export type ValueComponents = typeof AudiCopyM | typeof AudiHeadlineOrder3;

type ValueProps = AudiCopyProps | AudiHeadlineProps;

interface ValueBaseProps extends PriceProps {
  TagName: ValueComponents;
  children?: React.ReactElement;
}

interface financeParameter {
  id: string;
  text: string;
  unit: string;
}

const AudiBasketConfigurationValueBase: React.FC<ValueBaseProps> = ({
  field,
  rowField,
  additionalProps,
  additionalCss,
  TagName = AudiCopyM,
  footnoteRef,
  children,
  footnoteInverted,
}) => {
  const { basketState, basketDispatch } = useContext(AudiBasketContext);
  const numberFormatterService = basketState?.numberFormatterService;
  const checkValue = useConfiguratorData<number>(rowField);

  let value =
    checkValue && typeof checkValue === 'number' && checkValue !== 0
      ? numberFormatterService?.formatCurrency(checkValue)
      : checkValue;

  const financeParameterList = useConfiguratorData<Array<financeParameter>>('financeParameterList');

  if (field === 'prices.totalRate' && Array.isArray(financeParameterList)) {
    const MonthlyPayments = (payment: financeParameter): boolean => {
      return payment.id === 'MonthlyPayments';
    };
    const monthlyPayments = financeParameterList.find(MonthlyPayments);

    value = monthlyPayments ? `${monthlyPayments?.text} ${monthlyPayments?.unit}` : value;
  }

  let cssClass = 'audi-basket-configuration-value';
  cssClass += additionalCss ? ` ${additionalCss}` : '';

  return value ? (
    <div className={cssClass}>
      {children}
      <TagName {...additionalProps}>
        <span className="audi-basket-configuration-value__label">
          {value}
          {footnoteRef && (
            <FootnoteReference
              refId={footnoteRef}
              inverted={footnoteInverted}
              onClick={(): void => basketDispatch(createMobileViewExtendedAction(false))}
            />
          )}
        </span>
      </TagName>
    </div>
  ) : null;
};
