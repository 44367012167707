export const messages = {
  subtotal: {
    defaultMessage: 'Subtotal',
    id: 'fa.audi-price-details.subtotal',
  },
  deliveryCosts: {
    defaultMessage: 'Plus Delivery Costs',
    id: 'fa.audi-price-details.deliveryCosts',
  },
  dealerAdminFee: {
    defaultMessage: 'Dealer administration fee',
    id: 'fa.audi-basket.dealerAdminFee',
  },
  airConditioningLevy: {
    defaultMessage: 'Federal air conditioner tax',
    id: 'fa.audi-basket.airConditioningLevy',
  },
  audiCare: {
    defaultMessage: 'Audi Care',
    id: 'fa.audi-basket.audiCare',
  },
  estimatedMotorVehicleCounselFee: {
    defaultMessage: 'Estimated motor vehicle council fee',
    id: 'fa.audi-basket.estimatedMotorVehicleCounselFee',
  },
  greenLevy: {
    defaultMessage: 'Green Levy',
    id: 'fa.audi-basket.greenLevy',
  },
  luxuryTax: {
    defaultMessage: 'Luxury Tax',
    id: 'fa.audi-basket.luxuryTax',
  },

  basePrice: {
    defaultMessage: 'Basispreis',
    id: 'fa.audi-basket.basePrice',
  },
  change: {
    defaultMessage: 'ändern',
    id: 'fa.audi-basket.change',
  },
  equipment: {
    defaultMessage: 'Ausstattung',
    id: 'fa.audi-basket.equipment',
  },
  evVatTotal: {
    defaultMessage: 'MVA',
    id: 'fa.audi-basket.evVatTotal',
  },
  changeMonthlyRate: {
    defaultMessage: 'Change monthly rates',
    id: 'fa.audi-basket.changeMonthlyRates',
  },
  taxTotalWithoutVat: {
    defaultMessage: 'Registreringsavgift inkl. vrakpant',
    id: 'fa.audi-basket.taxTotalWithoutVat',
  },
  priceDetails: {
    defaultMessage: 'Details',
    id: 'fa.audi-basket.priceDetails',
  },
  priceModel: {
    defaultMessage: 'Modellpreis',
    id: 'fa.audi-basket.prices.model',
  },
  priceTotal: {
    defaultMessage: 'Gesamtpreis',
    id: 'fa.audi-basket.prices.total',
  },
  priceTotalBEV: {
    defaultMessage: 'PVP maximo',
    id: 'fa.audi-basket.prices.totalBEV',
  },
  showPricesAsRates: {
    defaultMessage: 'Alle Preise als Raten anzeigen',
    id: 'fa.audi-basket.showPricesAsRates',
  },
  totalRate: {
    defaultMessage: 'Rate',
    id: 'fa.audi-basket.prices.totalRate',
  },
  yourCarline: {
    defaultMessage: '',
    id: 'fa.audi-basket.yourCarline',
  },
  rotrRate: {
    defaultMessage: 'Rijklaarmaakkosten, recyclingbijdrage en leges',
    id: 'fa.audi-basket.rotrRate',
  },
  basePriceNetto: {
    defaultMessage: 'Basispreis Netto',
    id: 'fa.audi-basket.basePriceNetto',
  },
  destinationCharge: {
    defaultMessage: 'Destination Charge',
    id: 'fa.audi-basket.destinationCharge',
  },
  bpmTax: {
    defaultMessage: 'BPM tax',
    id: 'fa.audi-basket.bpmTax',
  },
  vat: {
    defaultMessage: 'VAT',
    id: 'fa.audi-basket.vat',
  },
  optionsPriceNetto: {
    defaultMessage: 'Optionspreis Netto',
    id: 'fa.audi-basket.optionsPriceNetto',
  },
  rotrPrice: {
    defaultMessage: 'ROTR',
    id: 'fa.audi-basket.rotrPrice',
  },
  companyCar: {
    defaultMessage: 'Autoetu',
    id: 'fa.audi-basket.companyCar',
  },
  taxableBenefit1: {
    defaultMessage: 'Käyttöedun verotusarvo',
    id: 'fa.audi-basket.taxableBenefit1',
  },
  taxableBenefit2: {
    defaultMessage: 'Vapaan autoedun verotusarvo',
    id: 'fa.audi-basket.taxableBenefit2',
  },
  generalPriceInformationDisclaimer: {
    defaultMessage: '',
    id: 'fa.audi-basket.generalPriceInformationDisclaimer',
  },
  taxTotal: {
    defaultMessage: 'Arvioitu autovero',
    id: 'fa.audi-basket.taxTotal',
  },
  closeLayer: {
    defaultMessage: 'schließen',
    id: 'nemo.ui.common.close',
  },
};
