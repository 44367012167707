import * as React from 'react';

import { ReactFeatureApp } from '@feature-hub/react';
import { FeatureAppDefinition, FeatureServices } from '@feature-hub/core';
import { ConfiguratorDataServiceV1 } from '@oneaudi/configurator-data-service';
import { I18NServiceV1 } from '@volkswagen-onehub/audi-i18n-service';
import { LocaleServiceV1 } from '@volkswagen-onehub/locale-service';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@volkswagen-onehub/audi-footnote-reference-service';
import { ConfiguratorContextComponent } from '@oneaudi/configurator-data-context';
import I18nContextComponent from '@volkswagen-onehub/audi-i18n-context';
import { AudiAudicodeServiceV1 } from '@volkswagen-onehub/audi-audicode-service';
import { AudiMarketContextServiceV1 } from '@volkswagen-onehub/audi-market-context-service';
import { ITrackingServiceV1 } from '@volkswagen-onehub/audi-tracking-service';
import { NumberFormatterServiceV1 } from '@oneaudi/number-formatter-service/dist/v1';

import { LayerManagerV25 } from '@volkswagen-onehub/layer-manager';
import { ThemeProvider } from '@audi/audi-ui-react-v2';
import { FootnoteReferenceContextComponent } from './utils/footnote-references/footnote-references-context';
import AudiBasket, { BasketConfig } from './components/audi-basket/audi-basket';
import { doFeatureAppReadyTracking } from './utils/helpers/tracking-helper';

interface Dependencies extends FeatureServices {
  readonly 'audi-configurator-data-service': ConfiguratorDataServiceV1;
  readonly 'dbad:audi-i18n-service': I18NServiceV1;
  readonly 'locale-service': LocaleServiceV1;
  readonly 'audi-audicode-service': AudiAudicodeServiceV1;
  readonly 'audi-footnote-reference-service': AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  readonly 'audi-market-context-service': AudiMarketContextServiceV1;
  readonly 'audi-tracking-service': ITrackingServiceV1;
  readonly 'layer-manager': LayerManagerV25;
  readonly 'audi-number-formatter-service': NumberFormatterServiceV1;
}

export const FEATURE_APP_NAME = 'oneaudi-feature-app-basket';

const DEFAULT_LANGUAGE = 'de';

export type AudiFeatureAppBasketConfig = ReactFeatureApp;

const featureAppDefinition: FeatureAppDefinition<ReactFeatureApp, Dependencies, BasketConfig> = {
  create: ({ featureServices: services, config }) => {
    const audiConfiguratorData = services['audi-configurator-data-service'];
    const i18nData: I18NServiceV1 = services['dbad:audi-i18n-service'];
    const audiCodeService = services['audi-audicode-service'];
    const marketContextService = services['audi-market-context-service'];
    const localeService: LocaleServiceV1 = services['locale-service'];
    const footnoteReferenceService: AudiFootnoteRefernceServiceScopeManagerInterfaceV3 =
      services['audi-footnote-reference-service'];
    const layerManager: LayerManagerV25 = services['layer-manager'];
    const numberFormatterService: NumberFormatterServiceV1 =
      services['audi-number-formatter-service'];

    const language = localeService.language ? localeService.language : DEFAULT_LANGUAGE;

    const trackingService = services['audi-tracking-service'];
    if (trackingService) {
      doFeatureAppReadyTracking(trackingService);
    }

    return {
      // disable eslint rule because this is a (Feature Hub) React Feature App
      // that doesn't define a `displayName` attribute
      // eslint-disable-next-line react/display-name
      render: (): React.ReactNode => (
        <I18nContextComponent i18nData={i18nData} language={language} scopes={[]}>
          <ConfiguratorContextComponent audiConfiguratorData={audiConfiguratorData}>
            <FootnoteReferenceContextComponent
              config={config}
              footnoteReferenceServiceScopeManager={footnoteReferenceService}
              marketContextService={marketContextService}
            >
              <ThemeProvider>
                <AudiBasket
                  config={config}
                  marketContextService={marketContextService}
                  audiCodeService={audiCodeService}
                  footnoteReferenceServiceScopeManager={footnoteReferenceService}
                  trackingService={trackingService}
                  layerManager={layerManager}
                  localeService={localeService}
                  numberFormatterService={numberFormatterService}
                />
              </ThemeProvider>
            </FootnoteReferenceContextComponent>
          </ConfiguratorContextComponent>
        </I18nContextComponent>
      ),
    };
  },
  dependencies: {
    externals: {
      react: '*',
      axios: '*',
      'react-dom': '*',
      'styled-components': '^5.1.1',
      '@audi/audi-ui-react-v2': '^3.3.1',
      '@feature-hub/react': '*',
    },
    featureServices: {
      'audi-configurator-data-service': '^1.0.0',
      'dbad:audi-i18n-service': '^1.0.0',
      'locale-service': '^1.0.0',
      'audi-footnote-reference-service': '3.0.0',
      'audi-market-context-service': '1.0.0',
      'audi-audicode-service': '1.0.0',
      'audi-number-formatter-service': '^1.0.0',
      'audi-dynamic-footnote-service': '1.0.0',
      'dbad:audi-vue-formatter-service': '^1.0.0',
    },
  },
  optionalDependencies: {
    featureServices: {
      'audi-tracking-service': '^1.0.0',
      'layer-manager': '^2.5.0',
    },
  },
};

export default featureAppDefinition;
