import './audi-basket.css';
import * as React from 'react';
import { useContext, useEffect, useMemo, useReducer, useRef, useState } from 'react';

import { ConfiguratorDataContext } from '@oneaudi/configurator-data-context';
import { LinkModel } from '@oneaudi/configurator-react-utils';
import { AudiAudicodeServiceV1 } from '@volkswagen-onehub/audi-audicode-service';
import { AudiMarketContextServiceV1 } from '@volkswagen-onehub/audi-market-context-service';
import { ITrackingServiceV1 } from '@volkswagen-onehub/audi-tracking-service';
import { AudiFootnoteRefernceServiceScopeManagerInterfaceV3 } from '@volkswagen-onehub/audi-footnote-reference-service';

import { LayerManagerV25 } from '@volkswagen-onehub/layer-manager';
import { NumberFormatterServiceV1 } from '@oneaudi/number-formatter-service/dist/v1';
import { isCurrentLocation } from '../../utils/helpers/is-current-location';
import { BasketState, basketReducer } from '../../utils/reducers/basket-reducer';
import {
  DisplayModeTypes,
  IBasketAction,
  createInitStateAction,
} from '../../utils/actions/basket-actions';
import { useIntersection } from '../../utils/hooks/use-intersection';
import { useWindowSize } from '../../utils/hooks/use-window-size';
import AudiBasketMeasure from './audi-basket-measure';
import AudiBasketOverViewCarinfo from '../audi-basket-overview-carinfo/audi-basket-overview-carinfo';
import AudiBasketOverviewActions from '../audi-basket-overview-actions/audi-basket-overview-actions';
import AudiBasketOverviewPrices from '../audi-basket-overview-prices/audi-basket-overview-prices';
import { getHeightWithMarginAndPadding } from '../../utils/helpers/get-total-height-of-element';

type BasketContextProps = {
  basketState?: BasketState | undefined;
  basketDispatch?: React.Dispatch<IBasketAction> | undefined;
};

export interface HandrailItem {
  URL: string;
  Label: string;
}

export type LinkTarget = '_self' | '_blank' | '_layer' | '_inpage';
export type LinkType = 'audicode' | 'pdf' | 'default';
export type MarketVariants = 'A' | 'A1' | 'B' | 'B_RATE' | 'C' | 'E' | 'F' | 'G';

export interface BasketConfig {
  Handrail?: HandrailItem[];
  CTA?: LinkModel;
  SummaryCTA?: LinkModel;
  SummaryPageUrl?: string;
  FinanceLink?: LinkModel;
  OneFootnoteDataUrl?: string;
  MarketVariant?: MarketVariants;
  ShowPriceFootnote?: boolean;
  DisplayFootnoteOnSecondLine?: boolean;
  DefaultDeliveryUrl?: string;
  SpecificDeliveryUrl?: string;
}

export interface AudiBasketProps {
  config: BasketConfig;
  marketContextService: AudiMarketContextServiceV1;
  audiCodeService: AudiAudicodeServiceV1;
  footnoteReferenceServiceScopeManager: AudiFootnoteRefernceServiceScopeManagerInterfaceV3;
  trackingService: ITrackingServiceV1;
  layerManager: LayerManagerV25;
  localeService: LocaleServiceV1;
  numberFormatterService: NumberFormatterServiceV1;
}

export const INITIAL_BASKET_STATE: BasketState = {
  detailsLayerOpen: false,
  displayMode: DisplayModeTypes.Desktop,
  extendedMobileView: false,
};

const defaulBasketContextProps = {
  basketState: undefined,
  basketDispatch: undefined,
};

export const AudiBasketContext = React.createContext<BasketContextProps>(defaulBasketContextProps);

export const fefa = {
  url: 'https://fa-footnote-engine.cdn.prod.arcade.apps.one.audi', // MARKETPLACE
  version: 'v2.3.1', // REACT 17 supported version
};

const MINIMAL_SCROLL_HEIGHT = 350;

const AudiBasket: React.FC<AudiBasketProps> = ({
  config = {},
  marketContextService,
  audiCodeService,
  footnoteReferenceServiceScopeManager,
  trackingService,
  layerManager,
  localeService,
  numberFormatterService,
}) => {
  const [isSticky, setIsSticky] = useState(true);
  const [isActive, setIsActive] = useState(false);
  const windowDimensions = useWindowSize();
  const [resetHeight, setResetHeight] = useState(false);
  const [basketWrapper, setBasketWrapper] = useState<HTMLElement | null>(null);
  const [availableWindowHeight, setAvailableWindowHeight] = useState(window.innerHeight);
  const basketContentRef = useRef<HTMLDivElement>(null);
  const carinfoRef = useRef<HTMLDivElement>(null);
  const pricesRef = useRef<HTMLDivElement>(null);
  const actionsRef = useRef<HTMLDivElement>(null);

  const basketWrapperRef = (node: HTMLElement): void => {
    if (node !== null) {
      setBasketWrapper(node);
    }
  };

  const { configuratorDataState } = useContext(ConfiguratorDataContext);
  const [basketState, basketDispatch] = useReducer<React.Reducer<BasketState, IBasketAction>>(
    basketReducer,
    INITIAL_BASKET_STATE,
  );

  const footer = document.querySelector('.nm-footer') as HTMLElement;
  const footerIntersection = useIntersection(footer, {
    threshold: 0,
  });

  useEffect(() => {
    if (footerIntersection) {
      const isFooterVisible = footerIntersection?.isIntersecting;
      setIsSticky(!isFooterVisible);
    }
  }, [footerIntersection]);

  useEffect(() => {
    basketDispatch(
      createInitStateAction(
        config,
        marketContextService,
        audiCodeService,
        footnoteReferenceServiceScopeManager,
        trackingService,
        layerManager,
        localeService,
        numberFormatterService,
      ),
    );
  }, [
    config,
    marketContextService,
    audiCodeService,
    footnoteReferenceServiceScopeManager,
    trackingService,
    layerManager,
    localeService,
    numberFormatterService,
  ]);

  useEffect(() => {
    const pageloadedHandler = (): void => {
      const { Handrail = [] } = config;
      const currentPageActive = Handrail.some((item) => isCurrentLocation(item.URL));

      setIsActive(currentPageActive);
    };

    document.addEventListener('PAGE_READY', pageloadedHandler);
    pageloadedHandler();

    return (): void => {
      if (document) {
        document.removeEventListener('PAGE_READY', pageloadedHandler);
      }
    };
  }, [config]);

  useEffect(() => {
    document.body.classList.toggle('u-audi-basket-active', isActive);
  }, [isActive]);

  // add classes to the wrapping tag
  useEffect(() => {
    if (basketWrapper) {
      const featureAppContainer = basketWrapper.closest('feature-app');
      if (featureAppContainer) {
        featureAppContainer.classList.add('audi-basket-wrapper');
      }
    }
  }, [basketWrapper]);

  useEffect(() => {
    if (basketWrapper) {
      setResetHeight(true);
    }
  }, [
    basketWrapper,
    windowDimensions,
    basketState.extendedMobileView,
    configuratorDataState,
    basketState.displayMode,
    isSticky,
  ]);

  useEffect(() => {
    if (resetHeight && basketWrapper && basketContentRef.current) {
      const {
        current: { clientHeight },
      } = basketContentRef;
      basketWrapper.style.height = `${clientHeight}px`;
      setAvailableWindowHeight(window.innerHeight - clientHeight);
      setResetHeight(false);
    }
  }, [resetHeight, basketWrapper, setAvailableWindowHeight]);

  useEffect(() => {
    if (carinfoRef.current && actionsRef.current && pricesRef.current) {
      const basketPaddings = 12 + 18 + 18; // space S + M + M (css tokens)
      const actionsRefHeight = getHeightWithMarginAndPadding(
        actionsRef.current,
        actionsRef.current.clientHeight,
      );
      const carinfoRefHeight = getHeightWithMarginAndPadding(
        carinfoRef.current,
        carinfoRef.current.clientHeight,
      );
      const newHeight =
        windowDimensions.height - (actionsRefHeight + carinfoRefHeight + basketPaddings);
      pricesRef.current.style.maxHeight = `${newHeight}px`;
    }
  }, [windowDimensions, basketState.extendedMobileView]);

  useEffect(() => {
    if (basketState.extendedMobileView && availableWindowHeight < MINIMAL_SCROLL_HEIGHT) {
      document.body.classList.add('u-audi-basket-expanded');
      if (!isSticky && basketWrapper) {
        basketWrapper.scrollIntoView();
      }
    } else {
      document.body.classList.remove('u-audi-basket-expanded');
    }
  }, [basketState.extendedMobileView, availableWindowHeight, basketWrapper, isSticky]);

  const basketClasses = useMemo((): string => {
    let classes = 'audi-basket';
    if (isSticky) {
      classes += ' audi-basket--sticky';
    }
    if (
      basketState.displayMode === DisplayModeTypes.Desktop ||
      basketState.displayMode === DisplayModeTypes.Tablet
    ) {
      classes += ' audi-basket--desktop';
    }
    if (!basketState.extendedMobileView && basketState.displayMode === DisplayModeTypes.Mobile) {
      classes += ' audi-basket--collapsed';
    }
    return classes;
  }, [basketState.displayMode, basketState.extendedMobileView, isSticky]);

  return isActive ? (
    <div ref={basketWrapperRef}>
      {/* eslint-disable-next-line react/jsx-no-constructed-context-values */}
      <AudiBasketContext.Provider value={{ basketDispatch, basketState }}>
        <div className={basketClasses} ref={basketContentRef}>
          <div className="audi-basket__content-wrapper">
            {basketState.displayMode !== DisplayModeTypes.Tablet && (
              <AudiBasketOverViewCarinfo ref={carinfoRef} />
            )}
            <AudiBasketOverviewPrices ref={pricesRef} />
            <AudiBasketOverviewActions ref={actionsRef} />
          </div>
        </div>
        <AudiBasketMeasure
          audiCodeService={audiCodeService}
          config={config}
          marketContextService={marketContextService}
          footnoteReferenceServiceScopeManager={footnoteReferenceServiceScopeManager}
          trackingService={trackingService}
          layerManager={layerManager}
          numberFormatterService={numberFormatterService}
        />
      </AudiBasketContext.Provider>
    </div>
  ) : null;
};

export default AudiBasket;
